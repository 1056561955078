import $axios from '../axios/index';
//新增或修改需求
export const apiSaveOrUpdate = (params)=>{
  return $axios.post('/cbmp/req/saveOrUpdate', params);
}
//查询需求
export const apiDemandList = (params)=>{
  return $axios.get('/cbmp/req/query', {params});
}
//查询需求
export const apiDemandDetail = (params)=>{
  return $axios.get('/cbmp/req/detail', {params});
}
//删除需求
export const apiPutTRequirement = (params)=>{
  return $axios.put('/cbmp/rest/TRequirement', params);
}
//新增咨询
export const apiPostConsultion = (params)=>{
  return $axios.post('/cbmp/rest/Consultion', params);
}
//新增收藏
export const apiPostRequirement  = (params)=>{
  return $axios.post('/cbmp/rest/RequirementFavoriteRel', params);
}
export const apiGetRequirement  = (params)=>{
  return $axios.get('/cbmp/rest/RequirementFavoriteRel/list', {params});
}
export const apiDeleteRequirement  = (params)=>{
  return $axios.delete('/cbmp/rest/RequirementFavoriteRel', params);
}
//取消收藏
export const apiDelRequirement  = (params)=>{
  return $axios.get('/cbmp/req/cancelFavorite', {params});
}
//新增收藏
export const apiFavorite  = (params)=>{
  return $axios.get('/cbmp/req/favorite', {params});
}
//推荐专家
export const apiExperts  = (params)=>{
  return $axios.get('/cbmp/req/getEnterpriseRecommendExperts', {
    params,
    noMsg: true
  });
}
//我的栏目
export const apiIndexMenu  = (params)=>{
  return $axios.get('/cbmp/cfg/menu', {params});
}


