let routers = [
  {
    path: '/en/Index',
    name: 'ENIndex',
    component: () => import(/* webpackChunkName: "view" */ '../views/Index'),
    meta: {
      title: "主页",
      menuIndex: 1,
    },
  },
  {
    path: '/en/Demand',
    name: 'ENDemand',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/Demand'),
    meta: {
      title: "需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "需求",
          lang: 'm2'
        },
      ]
    },
  },
  {
    path: '/en/DemandDetail',
    name: 'ENDemandDetail',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandDetail'),
    meta: {
      title: "需求详情",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "需求",
          lang: 'm2',
          path: "/en/Demand"
        },
      ]
    },
  },
  {
    path: '/en/DemandRelease',
    name: 'ENDemandRelease',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandRelease'),
    meta: {
      title: "发布需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "需求",
          lang: 'm2',
          path: "/en/Demand"
        },
        {
          name: "发布需求",
          lang: "DemandRelease"
        }
      ]
    },
  },
  {
    path: '/en/DemandCollection',
    name: 'ENDemandCollection',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandCollection'),
    meta: {
      title: "我对接的需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "需求",
          lang: 'm2',
        }
      ]
    },
  },
  {
    path: '/en/DemandMy',
    name: 'ENDemandMy',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandMy'),
    meta: {
      title: "我发布的需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "我发布的需求",
          lang: "DemandMy"
        }
      ]
    },
  },
  {
    path: '/en/RecommendToMe',
    name: 'ENRecommendToMe',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/RecommendToMe'),
    meta: {
      title: "推荐给我的需求",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "推荐给我的需求",
          lang: "RecommendToMe"
        }
      ]
    },
  },
  {
    path: '/en/UserInfoSet',
    name: 'ENUserInfoSet',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/UserInfoSet'),
    meta: {
      title: "基本信息设置",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "基本信息设置",
          lang: "baseInfoSet"
        }
      ]
    },
  },
  {
    path: '/en/UserPwd',
    name: 'ENUserPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/UserPwd'),
    meta: {
      title: "账户安全设置",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "账户安全设置",
          lang: "AccountSettings"
        }
      ]
    },
  },
  {
    path: '/en/SearchResult',
    name: 'ENSearchResult',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/SearchResult'),
    meta: {
      title: "搜索结果",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/en/Index"
        },
        {
          name: "搜索结果",
          lang: "SearchResult"
        }
      ]
    },
  },
  {
    path: '/en/UnderConstruction',
    name: 'ENUnderConstruction',
    component: () => import(/* webpackChunkName: "view" */ '../views/UnderConstruction'),
    meta: {
      title: "Under construction",
      menuIndex: 999,
      breadcrumb: []
    },
  },
  {
    path: '/en/EnterpriseInfo',
    name: 'ENEnterpriseInfo',
    component: () => import(/* webpackChunkName: "view" */ '../views/Enterprise/EnterpriseInfo'),
    meta: {
      title: "企业展示",
      menuIndex: 1,
    },
  },
  {
    path: '/en/ExpertInfo',
    name: 'ENExpertInfo',
    component: () => import(/* webpackChunkName: "view" */ '../views/Enterprise/ExpertInfo'),
    meta: {
      title: "专家展示",
      menuIndex: 1,
    },
  },
  {
    path: '/en/Notice',
    name: 'ENNotice',
    component: () => import(/* webpackChunkName: "view" */ '../views/Notice/Notice'),
    meta: {
      title: "通知信息列表",
      menuIndex: 1,
    },
  },
  {
    path: '/en/NoticeDetail',
    name: 'ENNoticeDetail',
    component: () => import(/* webpackChunkName: "view" */ '../views/Notice/NoticeDetail'),
    meta: {
      title: "通知信息详情",
      menuIndex: 1,
    },
  },
  {
    path: '/en/PublishingActivities',
    name: 'ENPublishingActivities',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/PublishingActivities'),
    meta: {
      title: "发布活动",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]

    },
  },
  {
    path: '/en/ActivityDetails',
    name: 'ENActivityDetails',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/ActivityDetails'),
    meta: {
      title: "活动详情",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]
    },
  },
  {
    path: '/ActivityList',
    name: 'ActivityList',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/ActivityList'),
    meta: {
      title: "我发布的活动",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]
    },
  }
];
export default routers
