import { apiCurruser } from '../../api/index';
export default {
  //namespaced: true,
  state: {
    btnLoading: false,
    publicKey:
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChULFF3NMp7zLTPvzeR7jRv5K0MJkVHi/iHMO19zEug5HJjfyEe2wQiSWtYSlKLrSn48DA4S4Q04XVwAZQ4LbZ/cznAqX+O7zj5GMZOntJ9cb4dToZjusFne4kjrxhgFZWpj92L2kxYrSPIHOrKngtIx1zAtHKDW63To3rIunn4QIDAQAB",
    userInfo: {},
    language: 'zh',
    profile: {}
  },
  getters: {
    getPublicKey(state){
      return state.publicKey;
    },
    getBtnLoading(state){
      return state.btnLoading;
    },
    getUserInfo(state){
      return state.userInfo;
    },
    getLanguage(state){
      return state.language;
    },
    getProfile(state){
      return state.profile;
    }
  },
  mutations: {
    upBtnLoading(state, data){
      state.btnLoading = data;
    },
    setUserInfo(state, data){
      state.userInfo = typeof data === 'object'?data:{};
      state.profile = data&&data.profile?JSON.parse(data.profile):{};
    },
    setLanguage(state, data){
      state.language = data;
    },
    setProfile(state, data){
      state.profile = data;
    }
  },
  actions: {
    upBtnLoading({commit}, data){
      commit('upBtnLoading', data);
    },
    setUserInfo({commit}, data){
      if(data){
        commit('setUserInfo', data);
      }else{
        apiCurruser().then(res=>{
          commit('setUserInfo', res);
        });
      }
    },
    setLanguage({commit}, data){
      commit('setLanguage', data);
    },
    setProfile({commit}, data){
      commit('setProfile', data);
    }
  }
}
