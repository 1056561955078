let routers = [
  {
    path: '/SignIn',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/SignIn'),
    meta: {
      title: "登录"
    },
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/SignUp'),
    meta: {
      title: "注册"
    },
  },
  {
    path: '/ResetPass',
    name: 'ResetPass',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ResetPwd'),
    meta: {
      title: "重置密码"
    },
  },
  {
    path: '/Tips',
    name: 'Tips',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/Tips'),
    meta: {
      title: "友情提示"
    },
  },
  {
    path: '/ForgotPwd',
    name: 'ForgotPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ForgotPwd'),
    meta: {
      title: "忘记密码"
    },
  },
  {
    path: '/ResetPwd',
    name: 'ResetPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ResetPwd'),
    meta: {
      title: "重置密码"
    },
  },
];
export default routers;
