const zh = {
  name: '中文',
  //index
  m1: '主页',
  m2: '需求',
  m3: '方案',
  m4: '活动',
  m5: '课程',
  m6: '更多',
  title1: '需求详情',
  tab1: '导航栏',
  tab2: '个人中心',
  tab3: '我的栏目',
  tab4: '热门事件',
  tab5: '专家推荐',
  tab6: '联盟联系人',
  RecommendToMe:'推荐给我的需求',

  l1_1: '个人资料',
  l1_2: '个人用户',
  leftMenu1: '我的需求',
  leftMenu2: '我的方案',
  leftMenu3: '我的活动',
  leftMenu4: '我的课程',
  leftMenu5: '更多设置',
  leftMenu6: '自定义展示菜单',
  leftMenuTip: '敬请期待',
  l3_1: '我的标签',
  l2_1: '我的栏目',
  r0_0: '换一组',
  r0_1: '推荐专家',
  r1_1: '联盟联系人',
  r1_2: '给专家留言',
  r1_3: '给该专家发送咨询信息',
  r2_1: '关注我们',
  r3: '宋海祥',
  r4: '业务经理',
  r5: '江苏省产业技术研究院',
  r6: '微信',
  r7: '邮件',
  baseInfoSet: '基本信息设置',
  AccountSettings: '账户安全设置',
  DemandRelease: '发布需求',
  DemandMy: '我发布的需求',
  SearchResult: '搜索结果',
  placeholder: '请输入 ',
  true: '是',
  false: '否',
  startDate: '开始日期',
  endDate: '结束日期',
  logout: '注销登录',
  search: '搜索',
  reset: '重置',
  delete1: '确定删除这条记录吗',
  delete2: '提示',
  delete3: '确定',
  delete4: '取消',
  delete5: '未查询到版本内容，是否添加',
  delete6: '还没有对应语言版本的需求，请移步查看其他需求',
  iCenter1: '热门',
  iCenter2: '长三角企业智能制造技术需求对接工作会',
  iCenter3: '为了增强智能制造领域专家与产业界的互动，更好地推动产业技术研究，国际智能制造联盟（筹）与长三角国家技术创新中心合作，拟举办线上“长三角企业智能制造技术需求对接工作会”。本次工作会议涉及10+个细分领域龙头企业，15+项技术需求，约3000万人民币企业意向出资额。',
  iCenter4: '活动时间',
  iCenter5: '2022年10月中旬',
  iCenter6: '活动地点',
  iCenter7: '线上',
  iCenter8: '我要参与',
  iCenter9: '已报名',
  iCenter10: '活动详情',
  iCenter11: '（拟定）',
  //SignUp
  signUp1: '用户注册',
  signUp2: '手机号',
  signUp2_1: '手机号注册',
  signUp3: '邮箱',
  signUp3_1: '邮箱注册',
  signUp4: '请输入手机号',
  signUp5: '请输入短信验证码',
  signUp6: '获取验证码',
  signUp7: '设置至少8位密码',
  signUp8_1: '我已阅读并同意',
  signUp8_2: '服务条款隐私政策',
  signUp8_3: '服务条款、隐私政策',
  signUp8_4: '和',
  signUp8_5: '保密协议',
  signUp9: '注册',
  signUp10: '已注册用户?',
  signUp11: '登录',
  signUp12: '请输入电子邮箱',
  signUp13: '请输入密码',
  signUp14: '请阅读并同意',
  signUp15:'请输入电子邮箱',
  //SignIn
  signIn1: '用户登录',
  signIn2: '忘记密码',
  signIn3: '登录',
  signIn4: '还没有注册？',
  signIn5: '申请注册',
  signIn6: '手机登录',
  signIn7: '邮箱登录',
  signIn8: '请输入手机号',
  signIn9: '请输入验证码',
  signIn10: '获取验证码',
  signIn11: '密码登录',
  signIn12: '验证码登录',
  //ForgotPwd
  forgotPwd1: '请输入注册的邮箱',
  forgotPwd2: '发送邮件',
  //ResetPwd
  resetPwd1: '重置密码',
  resetPwd2: '请输入新密码',
  resetPwd3: '请输入确认新密码',
  resetPwd4: '保存',
  //DemandMy
  demandMy1: '状态',
  demandMy2: '需求名称',
  demandMy3: '需求有效期',
  demandMy4: '需求预算（万）',
  demandMy5: '操作',
  demandMy6: '发需求',
  demandMy7: '查询',
  demandMy8: '最后修改日期',
  demandMy9: '需求类型',
  demandMy10: '需求所在城市',
  demandMy11: '发布提交时间',
  demandMy12: '留言',
  demandMy13: '发布人',
  demandMy14: '行业分类',
  demandMy15: '需求有效期',
  demandMy16: '保密协议签署时间',
  demandMy17: '对接',
  operationView: '查看',
  operationEdit: '编辑',
  operationAdd: '添加',
  operationDel: '删除',
  //UserInfoSet
  userinfo1: '修改密码',
  userinfo2: '个人资料',
  userinfo3: '身份',
  userinfo4: '个人会员',
  userinfo5: '修改头像',
  userinfo6: '姓名',
  userinfo7: '名',
  userinfo8: '姓',
  userinfo9: '性别',
  userinfo10: '所属区域',
  userinfo11: '出生日期',
  userinfo12: '所属行业',
  userinfo13: '技术领域',
  userinfo14: '技术标签',
  userinfo15: '最多支持3个技术标签',
  userinfo16: '输入个人技术标签',
  userinfo17: '添加',
  userinfo18: '手机号',
  userinfo19: '邮箱',
  userinfo20: '个人简介',
  userinfo21: '请输入您的个人简介',
  userinfo22: '公司',
  userinfo23: '部门',
  userinfo24: '职位',
  userinfo25: '请完善个人信息',
  userinfo26: '最多选3项',
  userinfo27: '专家信息',
  userinfo28: '基本资料',
  userinfo29: '研究方向',
  userinfo30: '头衔',
  userinfo31: '荣誉奖项',
  userinfo32: '主要科研成果',
  userinfo33: '案例展示',
  userinfo34: '媒体报道',
  userinfo35: '企业/单位信息',
  userinfo36: '企业/单位名称',
  userinfo37: '企业类型',
  userinfo38: '统一社会信用代码',
  userinfo39: '企业规模',
  userinfo40: '营业执照',
  userinfo41: '企业标志',
  userinfo42: '企业信息',
  userinfo43: '180 * 90',
  userinfo44: '100 * 100',
  //UserPwd
  userpwd1: '登录名',
  userpwd2: '最近登录地址',
  userpwd3: '旧密码',
  userpwd4: '请输入旧密码',
  userpwd5: '新密码',
  userpwd6: '请输入新密码',
  userpwd7: '再次输入密码',
  userpwd8: '再次请输入新密码',
  //StepOne
  stepOne1: '软硬件',
  stepOne2: '纯软件',
  stepOne3: '纯硬件',
  stepOne4: '多环节或复杂系统',
  stepOne5: '管理咨询',
  stepOne6: '人员协调/数字化转型..',
  stepOne7: '从下图选择一个需求类型',
  stepOne8: '请阅读并同意该协议',
  //StepBtn
  stepBtn1: '上一步',
  stepBtn2: '下一步',
  stepBtn3: '保存草稿',
  stepBtn4: '提交',
  //StepTwo
  stepTwo1: '基本信息',
  stepTwo2: '需求名称',
  stepTwo3: '需求所在地',
  stepTwo4: '需求有效期',
  stepTwo5: '需求预算',
  stepTwo6: '企业名称',
  stepTwo7: '合作方式',
  stepTwo8: '需求联系人',
  stepTwo9: '所属行业',
  stepTwo10: '最多支持添加5个行业',
  stepTwo11: '补充信息',
  stepTwo12: '企业是否要求拥有本次研发成果的所有权',
  stepTwo13: '是否允许专家/对接单位后续前往生产实地进行考察、调研',
  stepTwo14: '本需求是否愿意让专家进行系统性审视并重新进行技术需求的凝练',
  stepTwo15: '合作对象倾向',
  stepTwo16: '合作对象距离',
  stepTwo17: '规避企业',
  stepTwo18: '规避专家',
  stepTwo19: '需求之前的对接和解决情况',
  stepTwo20: '本需求企业内部的技术团队能够提供何种支持',
  stepTwo21: '最多支持添加3个规避企业',
  stepTwo22: '最多支持添加5个规避专家',
  stepTwo23: '最多只能选3个',
  stepTwo24: '由于可以设置多个值，请选择以后点 +添加 按钮',
  stepTwo25: '请输入邮箱地址',
  //typeA
  typeA1: '需求总体说明',
  typeA2: '本产品预期试用场景',
  typeA3: '预期的功能&指标',
  typeA4: '描述角度提示：什么样的用户，在什么样的环境下，执行什么样的动作，实现什么样的目的或者获得什么样的输出结果。',
  //LoopZb
  loopzb1: '描述角度提示：请填写功能点及具体技术角度。\n' +
    '注意：描述需求时，请不要描述该产品对国家和社会的重要作用，不要描述该产品所在领域的发展趋势。请区分“技术”和“目的”，例如：“用状态监控实现设备维护”是目的，“数字孪生”是技术，如果将某项技术列入功能或指标说明，就相当于明确要求了目的实现的路径',
  //typeB
  typeB1: '待设计或改造内容的基本情况',
  typeB2: '描述角度提示：\n' +
    '若为工艺设计类需求，请结合所希望生产的目标物，描述预期设想中的主要工艺流程（含软硬件设备）、所使用的工艺设计工具/方法、生产场所环境。\n' +
    '若为工艺改造类需求，请结合当前需求所涉及的所有相关流程环节，描述主要的工艺流程（含软硬件设备）、所使用的工艺设计工具/方法，以及贵公司目前自检后认为存在的主要工艺问题和对应的原因。\n' +
    '若为生产载体设计类需求，请围绕预期的生产目标物，描述主要涉及的软硬件设备、可用的土地/场所情况。 若为生产载体改造类需求，请围绕当前的生产目标物，描述主要的生产流程、所使用软硬件设备、所用的土地/场所情况。',
  typeB3: '期望改进后实现的目标',
  typeB4: '描述角度提示：\n' +
    '请描述预期工艺/预期生产载体能够实现的主要功能（含与当前情况对比后的提升点）。\n' +
    '注意：描述需求时，请不要描述该产品对国家和社会的重要作用，不要描述该产品所在领域的发展趋势。请区分“技术”和“目的”，例如：“用状态监控实现设备维护”是目的，“数字孪生”是技术，如果将某项技术列入功能或指标说明，就相当于明确要求了目的实现的路径。',
  //typeC
  typeC1: '对于待开发的系统性方案，预期的功能以及具体的技术指标',
  typeC2: '开发基础',
  typeC3: '基础',
  typeC4: '描述角度提示：请描述已经具备的软硬件基础（型号/主要功能）。',
  //typeE
  typeE1: '涉及的系统或环节',
  typeE2: '系统&环节',
  typeE3: '当前状态',
  typeE4: '描述角度提示：请按先后顺序，描述每个环节的名称、主要功能。',
  typeE5: '系统功能&指标',
  //typeF
  typeF1: '当前存在的主要问题',
  typeF2: '预期的解决方案或希望得到的支持',
  //DetailBar
  detailbar1: '无期限',
  detailbar2: '至',
  detailbar3: '面议',
  detailbar4: '万',
  //Demand
  demand1: '发布新需求',
  demand2: '我有意向对接的需求',
  demand3: '推荐',
  demand4: '过往',
  demand5: '请输入需求关键词',
  demand6: '请选择地区',
  demand7: '需求列表',
  //PreviewC
  previewC1: '本需求涉及的生产环节主要场景为',
  //XConsulting
  xconsulting1: '问题反馈',
  xconsulting2: '我要对接',
  xconsulting3: '您的咨询内容我们已收到',
  xconsulting4: '如果有必要，我们会在1-2个工作日内联系您。',
  xconsulting5: '关闭',
  xconsulting7: '确定',
  xconsulting6: '请输入咨询的问题\n' +
    '\n' +
    '您可以就需求的技术问题、商务合作提问，了解需求相关问题。',
  xconsulting8: '点击按钮报名参与对接，点击后可到我的收藏中查看此需求',
  xconsulting9: '您可以留言咨询此项需求，工作人员会依据您的咨询及时与您联系',
  //UnderConstruction
  uc1: '敬请期待',
  //保密协议
  baomixieyi1: '联盟需求交流保密协议',
  baomixieyi2: '为保障您的权益，请于每次登录本平台之前，仔细阅读以下规定。如果您登录或使用国际智能制造联盟网站，则应视为您已经详细阅读了本声明的内容，同意本声明的内容，同意遵守本声明的规定，且具有浏览平台信息，使用平台技术需求对接服务功能相应的权利能力和行为能力，并可对自己的行为承担法律责任。另外提醒您，平台有权对此声明进行适当变更，请仔细阅读。\n' +
    '第一条定义\n' +
    '1、平台：指国际智能制造联盟网站（www.icim.org.cn、www.online.icim.org.cn）。\n' +
    '2、平台运营方：江苏省产业技术研究院承建了联盟南京总部的建设运营工作并负责平台的日常运营和维护工作。\n' +
    '3、平台用户：指注册并使用本平台的主体，包括但不限于需求发布方、技术供给方和中间组织方。\n' +
    '4、需求发布方：指发布技术需求的主体，包括但不限于需求企业、机构、个人。\n' +
    '5、技术供给方：指提供技术研究或解决方案的主体，包括但不限于组织机构、企业、专家。' +
    '6、中间组织方：指为技术对接的实现提供专业服务的主体，包括但不限于国际智能制造联盟、江苏省产业技术研究院、长三角国家技术创新中心、国内外学会协会、职业技术经理人。\n' +
    '7、需求信息：指需求发布方在平台上传的和具体技术对接中披露的技术需求，包括但不限于技术方案、工程设计、电路设计、制造方法、配方、工艺流程、技术指标、计算机软件、数据库、研究开发记录、技术报告、检测报告、实验数据、测试结果、图纸、样品、样机、模型、模具、操作手册、技术文档、相关的函电。\n' +
    '第二条 需求信息的发布和处理\n' +
    '1、为实现需求对接的目的,需求发布方通过平台上传需求信息，并由平台运营方将需求信息通过平台公开展示给平台用户。\n' +
    '2、需求发布方应确保其上传需求信息的真实性、合法性，保证不侵犯任何其他方的合法权利。\n' +
    '3、平台运营方将有权查看、使用、对比、匿名化后公开、关联、统计收集、处理、存储平台用户使用本平台过程中产生的数据和平台判断后得出的结论数据。\n' +
    '第三条 保密义务\n' +
    '1、平台用户应对对接过程中知悉的需求信息承担保密义务，具体方式如下：\n' +
    '（1）针对各主体上传的且平台对全部平台用户公开的需求信息（简称公开需求信息），不得通过任何方式披露给非平台用户；\n' +
    '（1）针对各主体上传的且平台对全部平台用户公开的需求信息（简称公开需求信息），不得通过任何方式披露给非平台用户；\n' +
    '（2）对于具体技术对接过程中知悉的需求发布方提供的其他需求信息（简称其他需求信息），不得通过任何方式披露给任何第三方。\n' +
    '2、平台用户承诺采取合理的保密措施和制度保护该秘密，标准应不低于接受方为保护自有商业秘密所拥有的措施和制度。\n' +
    '3.上述保密条款不适用于下述情形:\n' +
    '（1）该需求信息已经或正在变成普通大众可以获取的资料;' +
    '（2）能书面证明从平台收到需求信息之前已经熟知该资料;\n' +
    '（3）由第三方合法提供给他的资料;\n' +
    '（4）有书面证明证实未使用拥有方的商业秘密而独立开发出来的客户资源和技术等。\n' +
    '4、返还信息\n' +
    '任何时候，只要收到需求发布方的书面要求，相关方应立即归还全部需求信息,包含该商业资料的媒介及其任何或全部复印件或者摘要。如果该需求信息属于不能归还的形式、或已经复制或转录到其他资料和载体中，则应删除。\n' +
    '第四条 用户细则\n' +
    '1、由于需求对接的前提是平台内用户账号，所以平台用户不应将其账号、密码转让或出借予他人使用。如用户发现其账号或平台服务遭他人使用，应立即通知平台运营方。因用户的保管疏忽导致账号、密码及平台服务遭他人非法使用，平台运营方有权拒绝承担任何责任。\n' +
    '2、平台用户须对自己在使用平台过程中的行为承担法律责任，包括但不限于对受到侵害者进行赔偿。\n' +
    '3、平台用户违反本声明或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿平台运营方与合作公司、关联公司，并使之免受损害，如平台运营方首先承担了因用户行为导致的行政处罚或赔偿责任后，用户应给予平台运营方等额的赔偿。\n' +
    '4、平台运营方有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收账号、追究法律责任等措施。对恶意注册账号或利用账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本声明的行为，平台运营方有权回收其账号。同时，平台运营方会视司法部门的要求，协助调查。\n' +
    '第五条 其他\n' +
    '1、本声明受中国法律管辖并按照中国法律进行解释。由于本声明的履行或解释而产生或与之有关的任何争议，由相关方友好协商解决，协商不成均提交南京仲裁委员会按照该会仲裁规则进行仲裁。\n' +
    '2、如平台用户基于需求对接过程达成了其他合作协议，则其他合作协议签约方之间的权利义务，应按照其他合作协议执行。\n' +
    '3、本声明置于国际智能制造联盟平台线上签署，勾选同意即视为生效，联盟网站将保留签署同意记录，对平台用户皆有约束力。\n',
  baomixieyi3: '国际智能制造联盟',
  //隐私政策条款
  yinsizhengce1: '不同意，退出',
  yinsizhengce2: '服务条款和隐私政策',
  yinsizhengce3: '国际智能制造联盟（筹）网站法律声明及隐私权政策',
  yinsizhengce4: '版本：2021年4月20日版',
  yinsizhengce5: '提示条款 \n' + '\n' +
    '您的信任对我们非常重要，我们深知用户信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。鉴此，国际智能制造联盟（英文名称：International Coalition of Intelligent Manufacturing简称"ICIM"或"我们"）制定本《法律声明及隐私权政策》（以下或简称"声明和政策"）并提醒您：国际智能制造联盟网站的各项服务前，请您务必仔细阅读并透彻理解本《法律声明和隐私权政策》，在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用本网站，将被视为对本声明和政策内容的接受和认可。如对本声明和政策内容有任何疑问、意见或建议，您可通过发送电子邮件至icim@jitri.cn或直接拨打电话025-58551017与我们联系。\n' + '\n' +
    '定义\n' + '\n' +
    '国际智能制造联盟网站：\n' +
    '官网域名为：http://www.icim.org.cn 、https://online.icim.org.cn \n' +
    '国际智能制造联盟（简称：ICIM）：是国际智能制造联盟网站的唯一版权所有者。\n' +
    '用户信息：用户信息是指您注册国际智能制造联盟网站的账户，使用我们的服务以及我们为了向您提供服务，您提交的或者我们收集的用户会员类信息，如姓名、联系方式等，具体详见隐私权政策第三条所述的信息。\n' + '\n' +

    '法律声明\n' + '\n' +
    '一、权利归属\n' +
    '1.1.本网站的LOGO、"国际智能制造联盟"、"ICIM"等文字、图形及其组合已经由国际智能制造联盟在中国注册商标。未经国际智能制造联盟书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。\n' +
    '1.2.除非国际智能制造联盟另行声明，国际智能制造联盟网站内的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归国际智能制造联盟服务提供者及/ 或其关联公司所有。未经国际智能制造联盟服务提供者及/或其关联公司许可，任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式使用国际智能制造联盟网站内的任何内容。\n' +
    '二、责任限制\n' +
    '2.1 本网站转载的文章或资料只是为了促进智能制造业国际间的交流、普及先进制造技术思想、推广制造业信息化技术，促进制造企业的发展，并非出于商业目的，同时并不意味国际智能制造联盟网站赞同其观点或证实其内容的真实性。\n' +
    '2.2 国际智能制造联盟网站在此提示，您在使用本网站提供的服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得以任何形式利用本网站从事侵犯他人名誉、隐私、知识产权或其他任何合法权益的活动。国际智能制造联盟网站不对您使用本网站所产生的任何法律后果承担任何责任。\n' +
    '隐私权政策\n' +
    '国际智能制造联盟网站（以下简称为"ICIM网站"或"我们"）尊重并保护用户信息，并且将以高度勤勉和审慎的义务对待这些信息。在您使用了本网站提供的服务时，我们将按照本隐私权政策收集、处理及披露您的信息。我们希望通过本隐私权政策向您清晰地介绍我们对您信息的处理方式，因此我们建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。\n' +
    '如果您有任何疑问、意见或建议，请通过ICIM网站提供的各种联系方式与我们联系。\n' +
    '本政策将帮助您了解以下内容：\n' +
    '一、适用范围\n' +
    '二、用户信息的收集和使用\n' +
    '三、我们将如何保护您的信息安全\n' +
    '四、我们如何共享、转让、公开披露您的个人信息\n' +
    '五、信息的合理利用及披露原则\n' +
    '六、Cookie和同类技术的使用\n' +
    '七、隐私权政策的通知和修订\n' +
    '八、如何联系我们\n' + '\n' +
    '一、适用范围\n' + '\n' +
    '本隐私权政策适用于ICIM网站所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过ICIM网站向您提供的技术服务。\n' + '\n' +
    '二、用户信息的收集和使用\n' + '\n' +
    '2.1 我们可能收集的信息\n' +
    '2.1.1 当您在本网站进行用户注册登记或使用本网站提供的服务时，您向本网站提供的一些个人或企业资料。这些资料可能包括：\n' +
    '1) 联系信息：如姓名、性别、年龄、出生日期、电话、传真、手机号码、QQ号、通信地址、住址、电子邮件地址等;\n' +
    '2.2 我们可能如何使用信息\n' +
    '我们可能将所收集的信息用作如下用途：\n' +
    '2.2.1 向您提供针对您需求的服务；\n' +
    '2.2.2 帮助本平台产品及服务的设计、优化和升级；\n' +
    '2.2.3 向您推送广告、消息通知或与您进行沟通；\n' +
    '2.3 如果您在本网站仅需使用浏览、搜索等基本服务，您不需要注册成为我们的会员及提供上述信息。\n' +
    '2.4 如果您主动要求注销账号时，请和我们联系，我们将尽快删除您的个人信息。\n' + '\n' +
    '三、我们将如何保护您的信息安全\n' + '\n' +
    '我们将对您的个人信息做符合行业标准的加密存储，并采取同样加密方式的异 地备份，防止您的个人信息丢失、被盗用或遭篡改。我们将采取周期性的扫描、定期的渗透测试服务等技术措施保障服务的安全可靠。我们会严格按照国家法律法规对您的个人信息进行管理及保护。但请您理解，可能存在黑客政击、计算机病毒侵入或发作、政府管制等影响信息安全的不可抗力因素。\n' + '\n' +
    '四、我们如何共享、转让、公开披露您的个人信息\n' + '\n' +
    '（一）共享\n' +
    '我们不会与ICIM网站服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：\n' +
    '1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。\n' +
    '2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。\n' +
    '3、与第三方服务商共享：当您在ICIM网站购买商品或服务时，为向您提供第三方物流及安装等服务，我们必须与第三方服务商共享您的个人信息。\n' +
    '4、与关联公司间共享：为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护ICIM网站关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息（如为便于您使用ICIM账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。\n' +
    '5、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。\n' +
    '目前，我们的授权合作伙伴包括以下类型：\n' +
    '（1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。\n' +
    '（2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。\n' +
    '对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。\n' +
    '（二）转让\n' +
    '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：\n' +
    '1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；\n' +
    '2、在ICIM网站服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。\n' +
    '（三）公开披露\n' +
    '我们仅会在以下情况下，公开披露您的个人信息：\n' +
    '1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；\n' +
    '2、如果我们确定您出现违反法律法规或严重违反ICIM网站相关协议规则的情况，或为保护ICIM网站及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或ICIM网站相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及ICIM网站已对您采取的措施。例如，若您销售假货而严重违反ICIM网站规则，我们可能会公开披露您的店铺主体信息与处罚情况。\n' + '\n' +
    '五、信息的合理利用及披露原则\n' + '\n' +
    '我们尊重并保护所有用户的个人信息，仅在符合下列条件之一，对收集之个人信息进行合理之利用及披露：\n' +
    '5.1 与国家安全、国防安全有关的；\n' +
    '5.2 与公共安全、公共卫生、重大公共利益有关的；\n' +
    '5.3 与犯罪侦查、起诉、审判和判决执行等有关的；\n' +
    '5.4 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
    '5.5 所收集的个人信息是个人信息主体自行向社会公众公开的；\n' +
    '5.6 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；\n' +
    '5.7 已取得您或您监护人的书面或等同于书面形式的同意或与您签订合同中所必需的；\n' +
    '5.8 用于维护所提供的产品与 / 或服务的安全稳定运行所必需的，例如发现、处置产品与 / 或服务的故障；\n' +
    '5.9 为合法的新闻报道所必需的；\n' +
    '5.10 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；\n' +
    '5.11 法律法规规定的其他情形。\n' + '\n' +
    '六、Cookie 和同类技术的使用\n' + '\n' +
    '6.1 Cookies是指一种技术，当使用者访问设有Cookies装置的本网站时，本网站之服务器会自动发送Cookies至您的浏览器内，并储存到您的电脑硬盘内，此Cookies便负责记录日后您到访本网站的种种活动、个人资料、浏览习惯、消费习惯甚至信用记录。\n' +
    '6.2 运用Cookies技术，本平台能够为您提供更加周到的个性化服务。本平台可能会运用Cookies追访您的浏览习惯等喜好，从而向您提供感兴趣的信息资料或储存密码，以便您造访本平台时不必每次重复输入密码。\n' +
    '6.3 如果您不希望个人信息保留在cookie中，您可以对浏览器等进行配置来禁用cookie，但完成关闭cookie的操作后，可能会影响到您使用本平台的服务。\n' + '\n' +
    '七、隐私权政策的通知和修订\n' + '\n' +
    '我们的《隐私权政策》的修改及更新权均属于国际智能制造联盟。如《隐私权政策》发生变更，我们将以在网站发布公告的方式来通知您，变更后的政策将从通知或网站规定的生效日期开始适用。若您在《隐私权政策》修订后继续使用本平台的服务，即表示您已充分阅读、理解并接受了修订后的政策并愿意受修订后的政策的约束。\n' + '\n' +
    '八、如何联系我们\n' + '\n' +
    '如果您对本声明和政策内容有任何疑问和意见，您可通过发送电子邮件至icim @jitri.cn或直接拨打电话025-58551017与我们联系。',
  //指导说明
  zhuyeshuoming1: '用户指南',
  zhuyeshuoming2: '导航栏',
  zhuyeshuoming3: '通过导航栏，您可以切换系统语言、选择您关注的板块、搜索系统内的信息或进入您的个人中心',
  zhuyeshuoming4: '个人中心',
  zhuyeshuoming5: '通过个人中心编辑和完善您的他个人信息，添加您的技术标签，以便系统根据您的信息，向您推荐相关信息',
  zhuyeshuoming6: '我的栏目',
  zhuyeshuoming7: '您可以通过配置自定义与您相关的栏目，通过添加或删除相关的栏目或子栏目，方便您更加聚焦自己感兴趣的活动事件',
  zhuyeshuoming8: '热门事件',
  zhuyeshuoming9: '主页工作区，我们为您推荐当前联盟发起的最新事件，方便您了解联盟近期热点活动',
  zhuyeshuoming10: '专家推荐',
  zhuyeshuoming11: '我们在专家推荐栏为您推荐联盟专家，方便您根据自身业务需求咨询联系',
  zhuyeshuoming12: '联盟联系人',
  zhuyeshuoming13: '您也可以直接联系我们，沟通与联盟相关合作事宜，或为您解答相关活动的疑问',
  //我对接需求
  dc1: '我对接需求',
  dc2: '取消对接',
  //MotherBoadr
  mboadr1: '新增',
  mboadr2: '请点新增添加',
  mboadr3: '编辑',
  mboadr4: '删除',
  //ExpertOrg
  eOrg1: '任职机构',
  eOrg2: '职务/职称',
  //HonorAward
  haward1: '奖项',
  haward2: '年份',
  haward3: '名称',
  haward4: '图片',
  haward5: '介绍',
  haward6: '附件',
  haward7: '地址',
  //Notice
  notice1: '通知信息列表',
  notice2: '全部消息',
  notice3: '未读消息',
  notice4: '已读消息',
  notice5: '全部类型',
  notice6: '站内信息',
  notice7: '邀请信息',
  notice8: '互动信息',
  notice9: '搜索',
  notice10: '删除',
  notice11: '标记已读',
  //NoticeDetail
  ndetail1: '返回通知列表',
  ndetail2: '尊敬的',
  ndetail3: '下载附件',
  //XHeader
  xheader1: '编辑资料',
  xheader2: '查看主页',
  xheader3: '全部通知消息',
  //DemandQuick
  dquick1: '输入需求标题，发布一条新需求',
  dquick2: '需求类型',
  dquick3: '同意',
  dquick4: '需求保密协议',
  dquick5: '发需求',
  dquick6: '关闭',
  dquick7: '请输入标题',
  dquick8: '请选择类型',
  //DemandSearch
  dsearch1: '推荐',
  dsearch2: '最新',
  dsearch3: '请输入需求关键词搜索',
  dsearch4: '高级搜索',
  dsearch5: '你的搜索条件',
  dsearch6: '关键词',
  //DemandDetail
  ddetail1: '需求信息',
  ddetail2: '有意向专家',
  ddetail3: '需求咨询',
  ddetail4: '留言咨询',
  ddetail5: '多行输入',
  ddetail6: '置顶',
  ddetail7: '权限',
  ddetail8: '附件',
  ddetail9: '发送',
  ddetail10: '留言列表',
  ddetail11: '请输入回复内容',
  //UploadFile
  ufile1: '上传附件',
  //ExpertInfo
  einfo1: '邀请对接需求',
  einfo2: '关注',
  einfo3: '取消关注',
  einfo4: '基本介绍',
  einfo5: '研究领域',
  einfo6: '年',
  einfo7: '成果',
  einfo8: '荣誉&奖项',
  einfo9: '原文链接',
  einfo10: '邀请',
  einfo11: '对接需求',
  einfo12: '邀请说明',
  einfo13: '请选择',
  einfo14: '请完善信息',
  einfo15: '介绍',
  einfo16: '案例',
  einfo17: '成果&荣誉',
  einfo18: '媒体报道',
  //ERight
  eright1: '关注他的',
  eright2: '企业',
  eright3: '专家',
  eright4: '暂无关注信息',
  eright5: '他关注的',
  //NoData
  nodata: '暂无数据',
  //MediaCoverage
  mcoverage1: '请输入报道URL地址，如https://www.baidu.com/',
  //前台 需求列表搜索 技术领域校验
  checkReqTechFieldIds:'最多选择3个技术领域',
}
export function getZhData(){
	return zh;
}
export default zh
