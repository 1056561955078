let routers = [
  {
    path: '/Index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "view" */ '../views/Index'),
    meta: {
      title: "主页",
      menuIndex: 1,
    },
  },
  {
    path: '/Demand',
    name: 'Demand',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/Demand'),
    meta: {
      title: "需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "需求",
          lang: 'm2'
        },
      ]
    },
  },
  {
    path: '/DemandDetail',
    name: 'DemandDetail',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandDetail'),
    meta: {
      title: "需求详情",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "需求",
          lang: 'm2',
          path: "/Demand"
        },
      ]
    },
  },
  {
    path: '/DemandRelease',
    name: 'DemandRelease',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandRelease'),
    meta: {
      title: "发布需求",
      menuIndex: 2,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "需求",
          lang: 'm2',
          path: "/Demand"
        },
        {
          name: "发布需求",
          lang: "DemandRelease"
        }
      ]
    },
  },
  {
    path: '/DemandCollection',
    name: 'DemandCollection',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandCollection'),
    meta: {
      title: "我对接的需求",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "需求",
          lang: 'm2',
        }
      ]
    },
  },
  {
    path: '/DemandMy',
    name: 'DemandMy',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/DemandMy'),
    meta: {
      title: "我发布的需求",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "我发布的需求",
          lang: "DemandMy"
        }
      ]
    },
  },
  {
    path: '/RecommendToMe',
    name: 'RecommendToMe',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/RecommendToMe'),
    meta: {
      title: "推荐给我的需求",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "推荐给我的需求",
          lang: "RecommendToMe"
        }
      ]
    },
  },
  {
    path: '/UserInfoSet',
    name: 'UserInfoSet',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/UserInfoSet'),
    meta: {
      title: "基本信息设置",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "基本信息设置",
          lang: "baseInfoSet"
        }
      ]
    },
  },
  {
    path: '/UserPwd',
    name: 'UserPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/UserPwd'),
    meta: {
      title: "账户安全设置",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "账户安全设置",
          lang: "AccountSettings"
        }
      ]
    },
  },
  {
    path: '/SearchResult',
    name: 'SearchResult',
    component: () => import(/* webpackChunkName: "view" */ '../views/Demand/SearchResult'),
    meta: {
      title: "搜索结果",
      menuIndex: 999,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "搜索结果",
          lang: "SearchResult"
        }
      ]
    },
  },
  {
    path: '/UnderConstruction',
    name: 'UnderConstruction',
    component: () => import(/* webpackChunkName: "view" */ '../views/UnderConstruction'),
    meta: {
      title: "建设中",
      menuIndex: 999,
      breadcrumb: []
    },
  },
  {
    path: '/EnterpriseInfo',
    name: 'EnterpriseInfo',
    component: () => import(/* webpackChunkName: "view" */ '../views/Enterprise/EnterpriseInfo'),
    meta: {
      title: "企业展示",
      menuIndex: 1,
    },
  },
  {
    path: '/ExpertInfo',
    name: 'ExpertInfo',
    component: () => import(/* webpackChunkName: "view" */ '../views/Enterprise/ExpertInfo'),
    meta: {
      title: "专家展示",
      menuIndex: 1,
    },
  },
  {
    path: '/Notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "view" */ '../views/Notice/Notice'),
    meta: {
      title: "通知信息列表",
      menuIndex: 1,
    },
  },
  {
    path: '/NoticeDetail',
    name: 'NoticeDetail',
    component: () => import(/* webpackChunkName: "view" */ '../views/Notice/NoticeDetail'),
    meta: {
      title: "通知信息详情",
      menuIndex: 1,
    },
  },
  {
    path: '/PublishingActivities',
    name: 'PublishingActivities',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/PublishingActivities'),
    meta: {
      title: "发布活动",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]
    },
  },
  {
    path: '/ActivityDetails',
    name: 'ActivityDetails',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/ActivityDetails'),
    meta: {
      title: "活动详情",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]
    },
  },
  {
    path: '/ActivityList',
    name: 'ActivityList',
    component: () => import(/* webpackChunkName: "view" */ '../views/Activity/ActivityList'),
    meta: {
      title: "我发布的活动",
      menuIndex: 4,
      breadcrumb: [
        {
          name: "主页",
          lang: 'm1',
          path: "/Index"
        },
        {
          name: "活动",
          lang: "m4"
        }
      ]
    },
  }
];
export default routers
