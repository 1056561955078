let routers = [
  {
    path: '/en/SignIn',
    name: 'ENSignIn',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/SignIn'),
    meta: {
      title: "登录"
    },
  },
  {
    path: '/en/SignUp',
    name: 'ENSignUp',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/SignUp'),
    meta: {
      title: "注册"
    },
  },
  {
    path: '/en/ResetPass',
    name: 'ENResetPass',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ResetPwd'),
    meta: {
      title: "重置密码"
    },
  },
  {
    path: '/en/Tips',
    name: 'ENTips',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/Tips'),
    meta: {
      title: "友情提示"
    },
  },
  {
    path: '/en/ForgotPwd',
    name: 'ENForgotPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ForgotPwd'),
    meta: {
      title: "忘记密码"
    },
  },
  {
    path: '/en/ResetPwd',
    name: 'ENResetPwd',
    component: () => import(/* webpackChunkName: "view" */ '../views/Login/ResetPwd'),
    meta: {
      title: "重置密码"
    },
  },
];
export default routers;
