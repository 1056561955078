import Vue from 'vue';
import Vuex from 'vuex';
import base from './modules/base';
import demand from './modules/demand';
import dict from './modules/dict';
import notice from './modules/notice';
import activity from './modules/activity';

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    base,
    demand,
    dict,
    notice,
    activity
  },
  state: {
    breadcrumb: [],
    menuIndex: 1
  },
  getters: {
    getBreadcrumb(state){
      return state.breadcrumb||[];
    },
    getMenuIndex(state){
      return state.menuIndex||999;
    },
  },
  mutations: {
    setBreadcrumb(state, data){
      state.breadcrumb = data;
    },
    setMenuIndex(state, num){
      state.menuIndex = num;
    },
  },
  actions:{
    setBreadcrumb({commit}, data){
      commit('setBreadcrumb', data);
    },
    setMenuIndex({commit}, num){
      commit('setMenuIndex', num);
    },
  }
});

export default store
