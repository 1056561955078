import Vue from 'vue';
import App from './App.vue';
import ELEMENT from 'element-ui';
import router from './router/index';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/element-#0069A2/index.css';
import './assets/style/style.scss';
import './assets/iconfont/iconfont.css';
import store from './store';

import i18n from './lang';
Vue.config.productionTip = false
Vue.use(ELEMENT, { size: 'mini'});

/*引入过滤器*/
import * as filters from './filters/index'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.back = () => router.back(-1);
Vue.prototype.$t = (args) =>{
  return i18n.tc.call(i18n, args);
};
Vue.directive("back", {
  bind: (el)=>{
    el.addEventListener("click", ()=>{
      window.history.back();
    })
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
