import $axios from '../axios/index';
//注册
export const apiRegister = (params)=>{
  return $axios.post('/cbmp/user/register', params);
}
//管理端登录
export const apiSystemLogin = (params)=>{
  return $axios.post('/cbmp/enter', params);
}
//登录
export const apiLogin = (params)=>{
  return $axios.post('/cbmp/user/login', params);
}
//注销
export const apiExit = ()=>{
  return $axios.get('/cbmp/exit');
}
//找回密码发送邮件
export const apiResetPwdByEmail = (params)=>{
  return $axios.post('/cbmp/user/resetPwdByEmail', params);
}
//重置密码
export const apiResetPwd = (params)=>{
  return $axios.post('/cbmp/user/resetPwd', params);
}
//查询用户信息
export const apiCurruser = ()=>{
  return $axios.get('/cbmp/cbmpuser');
}

//查询用户信息
export const apiGetAppUser = (params)=>{
  return $axios.get('/cbmp/rest/AppUser', {params});
}
//新增用户信息
export const apiPostAppUser = (params)=>{
  return $axios.post('/cbmp/rest/AppUser', params);
}
//修改用户信息
export const apiPutAppUser = (params, config)=>{
  return $axios.put('/cbmp/rest/AppUser', params, config);
}
//修改用户信息
export const apiChangePassword = (params)=>{
  return $axios.post('/cbmp/change/password', params);
}
//修改用户信息
export const apiSendSms = (params)=>{
  return $axios.post('/cbmp/user/sendSmsCheckcode', params);
}


