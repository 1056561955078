const Layout = () => import( /* webpackChunkName: "view" */ '../components/Layout/Layout')
let routers =  [
	{
		path: '/System/Login',
		name: 'SystemLogin',
		component: () => import( /* webpackChunkName: "view" */ '../views/System/Login/SignIn'),
		meta: {
			title: "登录"
		},
	},
	{
		path: '/System',
		name: 'SystemIndex',
		redirect: '/System/index',
		component: Layout,
		children: [{
				path: '/System/index',
				name: 'index',
				component: () => import( /* webpackChunkName: "view" */ '../views/System/RequirementManage/List'),
				meta: {
					title: "工作台"
				},
			},
			{
				path: '/System/SysUser',
				name: 'SysUser',
				component: () => import( /* webpackChunkName: "view" */ '../views/System/UserManage/Main'),
				meta: {
					title: "用户管理"
				},
			},
			{
				path: '/System/Message',
				name: 'Message',
				component: () => import( /* webpackChunkName: "view" */ '../views/System/MessageManage/Main'),
				meta: {
					title: "留言管理"
				},
			},
			{
				path: '/System/Notice',
				name: 'SystemNotice',
				component: () => import( /* webpackChunkName: "view" */ '../views/System/Notice/NoticeMana'),
				meta: {
					title: "通知管理"
				},
			},
			{
				path: '/System/NoticeSend',
				name: 'NoticeSend',
				component: () => import( /* webpackChunkName: "view" */ '../views/System/Notice/NoticeSend'),
				meta: {
					title: "通知管理发布"
				},
			}
		]
	},
];
export default routers;
