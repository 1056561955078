export default {
  //namespaced: true,
  state: {
    activity: {}
  },
  getters: {
    getActivity(state){
      return state.activity;
    }
  },
  mutations: {
    setActivity(state, data){
      state.activity = data;
    },
  },
  actions: {
    setActivity({commit}, data){
      commit('setActivity', data);
    },
  }
}
