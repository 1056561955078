import {apiGetNotice} from "../../api/Notice";

export default {
  //namespaced: true,
  state: {
    details: {},
    noticeSize: 0,
    noticeData: []
  },
  getters: {
    getNoticeDetails(state){
      let storage = window.localStorage.getItem('setDetails')||'{}';
      return {
        ...state.details,
        ...JSON.parse(storage)
      };
    },
    getNoticeSize(state){
      return state.noticeSize;
    },
    getNoticeData(state){
      return state.noticeData;
    }
  },
  mutations: {
    setDetails(state, data){
      state.details = data;
    },
    setNoticeSize(state, data){
      state.noticeSize = data;
    },
    setNoticeData(state, data){
      state.noticeData = data;
    }
  },
  actions: {
    setDetails({commit}, data){
      window.localStorage.setItem('setDetails', JSON.stringify(data));
      commit('setDetails', data);
    },
    setNoticeSize({commit}){
      apiGetNotice({
        isViewed: false
      }).then(res=>{
        if(res.success){
          commit('setNoticeData', res.result.splice(0,5));
          commit('setNoticeSize', res.size);
        }
      });
    },
  }
}
