import { apiRegister, apiLogin,apiSystemLogin, apiResetPwdByEmail, apiResetPwd, apiCurruser, apiPutAppUser,apiPostAppUser, apiChangePassword, apiExit, apiSendSms } from './login';
import { apiArea, apiIndustry, apiTechnicalField, apiTechnologyKeyword, apiPostTechnologyKeyword, apiEnterprise, apiExpert, apiMobilePrefix, apiPostEnterprise, apiPostExpert } from './dict';
import { apiSaveOrUpdate, apiDemandList, apiDemandDetail, apiPutTRequirement, apiPostConsultion, apiPostRequirement, apiFavorite, apiExperts, apiDelRequirement, apiIndexMenu, apiGetRequirement, apiDeleteRequirement } from './demand';
import $axios from "../axios";

const apiPostService = (url, params, type = 'post',config={}) => {
  return $axios[type](url, params,config);
}

export {
  apiPostService,
  //login
  apiRegister, apiLogin, apiSystemLogin,apiResetPwdByEmail, apiResetPwd, apiCurruser, apiPutAppUser,apiPostAppUser, apiChangePassword, apiExit, apiSendSms,
  //dict
  apiArea, apiIndustry, apiTechnicalField, apiTechnologyKeyword, apiPostTechnologyKeyword, apiEnterprise, apiExpert,apiMobilePrefix, apiPostEnterprise, apiPostExpert,
  //demand
  apiSaveOrUpdate, apiDemandList, apiDemandDetail, apiPutTRequirement, apiPostConsultion, apiPostRequirement, apiFavorite,apiExperts, apiDelRequirement, apiIndexMenu, apiGetRequirement, apiDeleteRequirement
};



