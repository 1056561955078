
//时间过滤
export const formatDate = (value, type) => {
  if(value==null){ return ''; }
  let dates = new Date(value);
  //dates.setHours(dates.getHours()+8);
  let year = dates.getFullYear();
  let month = (dates.getMonth()+1);
  if(month<10) month = '0' + month;
  let day = dates.getDate();
  if(day<10) day = '0' + day;
  let hours = dates.getHours();
  if(hours<10) hours = '0' + hours;
  let minutes = dates.getMinutes();
  if(minutes<10) minutes = '0' + minutes;
  let seconds = dates.getSeconds();
  if(seconds<10) seconds = '0' + seconds;
  if(type==='datetime') {
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  }else{
    return year+'-'+month+'-'+day;
  }
}

export const interceptStr = (value, n=200)=>{
  if(!value){
    return "";
  }else{
    return value.substr(0, n);
  }
}
//把带_的字符串转为、连接
export const re_str = (value)=>{
  if(!value){
    return "";
  }else{
    return value.split('_').join('、');
  }
}
//多久前
export const longAgo = (value)=>{
  if(!value){
    return "";
  }
  let date1 = new Date().getTime();
  let date2 = new Date(value).getTime();
  let minutes = (date1-date2)/60/1000;
  if(minutes < 1){
    return '刚刚';
  }else if(minutes < 60){
    return `${minutes.toFixed(0)}分钟前`;
  }else if(minutes/60 < 24){
    return `${(minutes/60).toFixed(0)}小时前`;
  }else{
    return `${(minutes/60/24).toFixed(0)}天前`;
  }
}
