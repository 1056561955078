import Vue from 'vue';
import Router from 'vue-router';
import xz from './xz';
import xzEn from './xzEn';
import zz from './zz';
import login from './login';
import loginEn from './loginEn';
const originalPush = Router.prototype.push;
Router.prototype.push = function(location) {
  return originalPush.call(this, location).catch(() => {});
};
Vue.use(Router);


let routers = [
  /*{
    path: '/',
    redirect: '/SignIn',
  },*/
  ...xz,
  ...zz,
  ...login,
  ...loginEn,
  ...xzEn
];

export default new Router({
  routes: routers
})
