const en = {
  name: 'English',
  //index
  m1: 'Home',
  m2: 'Demands',
  m3: 'Solutions',
  m4: 'Events',
  m5: 'Courses',
  m6: 'More',
  title1: 'Demand details',
  tab1: 'Navigation bar',
  tab2: 'Personal Center',
  tab3: 'Custom Menu',
  tab4: 'Current Events',
  tab5: 'Expert Recommendations',
  tab6: 'Contact Us',
  RecommendToMe:'Recommend to me',
  l1_1: 'Personal Data',
  l1_2: 'Personal Account',
  leftMenu1: 'My Demands',
  leftMenu2: 'My Solutions',
  leftMenu3: 'My Events',
  leftMenu4: 'My Courses',
  leftMenu5: 'More settings',
  leftMenu6: 'Custom Menu',
  leftMenuTip: 'Coming soon',
  l3_1: 'My tag',
  l2_1: 'Custom Menu',
  r0_0: 'Change',
  r0_1: 'Recommended experts',
  r1_1: 'Contact ICIM',
  r1_2: 'Send message',
  r1_3: 'Send consulting information to the expert',
  r2_1: 'Follow us',
  r3: 'Haiyang Song',
  r4: 'Bussiness Manger',
  r5: 'JITRI (Jiangsu Industrial Technology Research Institute)',
  r6: 'WeChat',
  r7: 'Email',
  baseInfoSet: 'baseInfoSet',
  AccountSettings: 'AccountSettings',
  DemandRelease: 'DemandRelease',
  DemandMy: 'DemandRelease',
  SearchResult: 'SearchResult',
  placeholder: 'Please enter ',
  true: 'true',
  false: 'false',
  startDate: 'Start date',
  endDate: 'End date',
  logout: 'logout',
  search: 'Search',
  reset: 'Reset',
  delete1: 'Are you sure to delete this record',
  delete2: 'Tips',
  delete3: 'Sure',
  delete4: 'Cannel',
  delete5: 'The version content is not found. Do you want to add it',
  delete6: 'There is no requirement corresponding to the language version, please step by step to view other requirements',
  iCenter1: 'Hot',
  iCenter2: 'Technology Needs Matching Series for Intelligent Manufacturing',
  iCenter3: 'International Coalition of Intelligent Manufacturing (ICIM) has set up an online program to facilitate better connection between the ICIM worldwide experts and enterprises in the field of intelligent manufacturing. It is a series of annual conferences. The program aims at bridging the industry-university research collaboration, and providing an interface between the industry field needs and the researchers. ',
  iCenter4: 'Event time',
  iCenter5: 'Mid-October 2022',
  iCenter6: 'Venue',
  iCenter7: 'Online',
  iCenter8: 'Register',
  iCenter9: 'Registered',
  iCenter10: 'Event Details',
  iCenter11: '(TBD)',
  //SignUp
  signUp1: 'Sign Up',
  signUp2: 'Phone Number',
  signUp2_1: 'Mobile number registration',
  signUp3: 'Email',
  signUp3_1: 'Email registration',
  signUp4: 'Please enter your phone number',
  signUp5: 'Please enter SMS verification code',
  signUp6: 'Get Verification Code',
  signUp7: 'Password must be at least 8 characters in length',
  signUp8_1: 'I have read and agree',
  signUp8_2: 'Terms and Conditions and Privacy Policy',
  signUp8_3: 'Please read and agree to ICIM\'s Terms and Conditions and Privacy Policy',
  signUp8_4: 'and',
  signUp8_5: 'Confidentiality agreement',
  signUp9: 'Register',
  signUp10: 'Already have an account?',
  signUp11: 'Login here',
  signUp12: 'Please enter your email address',
  signUp13: 'Please enter your password',
  signUp14: 'Please read and agree to',
  signUp15: 'Please enter your email address or phone number',
  //SignIn
  signIn1: 'Log in',
  signIn2: 'Forgot password',
  signIn3: 'Log in',
  signIn4: 'Don\'t have a account? ',
  signIn5: 'Register now',
  signIn6: 'Mobile Login',
  signIn7: 'Account Login',
  signIn8: 'Please enter your mobile number',
  signIn9: 'Please enter the verification code',
  signIn10: 'Get verification code',
  signIn11: 'Password Login',
  signIn12: 'Authentication Code Login',
  //ForgotPwd
  forgotPwd1: 'Please enter your registered email address',
  forgotPwd2: 'Send email',
  //ResetPwd
  resetPwd1: 'Password Reset',
  resetPwd2: 'Please enter your new password',
  resetPwd3: 'Please enter your new password again',
  resetPwd4: 'Save',
  //DemandMy
  demandMy1: 'Status',
  demandMy2: 'Name of Demand',
  demandMy3: 'Period of Validity',
  demandMy4: 'Budget (Unit: ¥10,000)',
  demandMy5: 'Operation',
  demandMy6: 'Post a Demand',
  demandMy7: 'Search',
  demandMy8: 'Last modified',
  demandMy9: 'Demand type',
  demandMy10: 'Demand city',
  demandMy11: 'Submission time',
  demandMy12: 'Message',
  demandMy13: 'Publisher',
  demandMy14: 'Industry Category',
  demandMy15: 'Period of Validity',
  demandMy16: 'Signing time of confidentiality agreement',
  demandMy17: 'Docking',
  operationView: 'View',
  operationEdit: 'Edit',
  operationAdd: 'Add',
  operationDel: 'Delete',
  //UserInfoSet
  userinfo1: 'Change Password',
  userinfo2: 'Personal Data',
  userinfo3: 'Identity',
  userinfo4: 'Individual Member',
  userinfo5: 'Change Profile Picture',
  userinfo6: 'Name',
  userinfo7: 'First Name',
  userinfo8: 'Last Name',
  userinfo9: 'Gender',
  userinfo10: 'Region',
  userinfo11: 'Date of Birth',
  userinfo12: 'Industry',
  userinfo13: 'Technology Domain',
  userinfo14: 'Technology Label(s)',
  userinfo15: 'Up to 3 labels',
  userinfo16: 'Enter your technology label(s)',
  userinfo17: 'Add',
  userinfo18: 'Phone Number',
  userinfo19: 'Email Address',
  userinfo20: 'Personal Introduction',
  userinfo21: 'Please enter your personal introduction',
  userinfo22: 'Company',
  userinfo23: 'Department',
  userinfo24: 'Position',
  userinfo25: 'Please complete your personal information',
  userinfo26: 'Select 3 items at most',
  userinfo27: 'Expert information',
  userinfo28: 'Basic information',
  userinfo29: 'Research direction',
  userinfo30: 'Title',
  userinfo31: 'Honorary awards',
  userinfo32: 'Scientific research achievements',
  userinfo33: 'Case presentation',
  userinfo34: 'Media coverage',
  userinfo35: 'Enterprise/unit information',
  userinfo36: 'Name of enterprise/unit',
  userinfo37: 'Enterprise type',
  userinfo38: 'Unified social credit code',
  userinfo39: 'Enterprise size',
  userinfo40: 'Business license',
  userinfo41: 'Enterprise logo',
  userinfo42: 'Enterprise information',
  userinfo43: '180 * 90',
  userinfo44: '100 * 100',
  //UserPwd
  userpwd1: 'Login Name',
  userpwd2: 'Last Login Location',
  userpwd3: 'Old Password',
  userpwd4: 'Please enter your old  password',
  userpwd5: 'New Password',
  userpwd6: 'Please enter your new password',
  userpwd7: 'Enter Again',
  userpwd8: 'Please enter your new password again',
  //StepOne
  stepOne1: 'Software & hardware',
  stepOne2: 'Software',
  stepOne3: 'Hardware',
  stepOne4: 'Multiple links or complex systems',
  stepOne5: 'Management consulting',
  stepOne6: 'People coordination/digital transformation..',
  stepOne7: 'Select a requirement type from the following figure',
  stepOne8: 'Please read and agree to this agreement',
  //StepBtn
  stepBtn1: 'Last Step',
  stepBtn2: 'Next Step',
  stepBtn3: 'Save as a Draft',
  stepBtn4: 'Submit',
  //StepTwo
  stepTwo1: 'Basic Information',
  stepTwo2: 'Name of Demand',
  stepTwo3: 'Location of Demand',
  stepTwo4: 'Period of Validity',
  stepTwo5: 'Budget',
  stepTwo6: 'Company Name',
  stepTwo7: 'Method of Cooperation',
  stepTwo8: 'PoC of Demand',
  stepTwo9: 'Industry Classification',
  stepTwo10: 'Add up to 5 industries',
  stepTwo11: 'Supplementary Information',
  stepTwo12: 'Whether the company claims ownership of this research and development results?',
  stepTwo13: 'Whether the experts/cooperating employers are allowed to visit the production site for subsequent investigation and research?',
  stepTwo14: 'For this demand, are you willing to let experts take a systematic look at it and reconfirm its technical requirements?',
  stepTwo15: 'Partner\'s Preferences',
  stepTwo16: 'Distance between You and Your Partner',
  stepTwo17: 'Company(-ies) to Avoid',
  stepTwo18: 'Expert(s) to Avoid',
  stepTwo19: 'Which teams/companies have been invited to work on this demand? How has it been resolved? What solutions have they left behind?',
  stepTwo20: 'What kind of support could the internal technical team provide for this demand?',
  stepTwo21: 'At most 3 circumvention enterprises can be added',
  stepTwo22: 'You can add up to 5 circumvention experts',
  stepTwo23: 'You can only select 3 at most',
  stepTwo24: 'Since multiple values can be set, please select the "later point + add" button',
  stepTwo25: 'Please enter email address',
  //typeA
  typeA1: 'Type of Demand',
  typeA2: 'Anticipated Application Scenario',
  typeA3: 'Anticipated Functions and Index',
  typeA4: 'Description tips: user introduction, usage environment, actions need to be taken, and goals can be achieved or results can be obtained.',
  //LoopZb
  loopzb1: 'Description tips: please fill in the function points and specific technologies.\n' +
    'Note: Please neither describe the important roles this product played to the country and the society, nor the development trend of its field in the demand description. Please make a distinction between “technology” and “goal”. For example, “make use of status monitoring to maintain equipment” is the goal, while “Digital Twin” is the technology. If a certain technology is listed in the functions or index, it is equivalent to putting forward an explicit request for the path to accomplishing goals.',
  //typeB
  typeB1: 'Basic information of the targets to be designed or reformed',
  typeB2: 'Description tips:\n' +
    'For demands of process design, please describe the anticipated main process flow (including software and hardware equipment), the process design tools/approaches used and the environment of the production site, combining with the targets desired to be produced.\n' +
    'For demands of process reform, please describe the main process flow (including software and hardware equipment), the process design tools/approaches used, and the existing major process problems and corresponding causes found through your self-inspection, combining with all relevant procedures involved in the current demand.\n' +
    'For demands of production carrier design, please describe the software and hardware equipment mainly involved and conditions of the available land/site around the anticipated production targets.\n' +
    'For demand of production carrier reform, please describe the major production flow, software and hardware equipment used and conditions of the land/site used around the current production targets.',
  typeB3: 'Goals that are expected to be achieved after improvement',
  typeB4: 'Description tips: please describe the main functions (including upgrades of current functions) that can be realized by the anticipated process/anticipated production carrier.\n' +
    'Note: Please neither describe the important roles this product played to the country and the society, nor the development trend of its field in the demand description. Please make a distinction between “technology” and “goal”. For example, “make use of status monitoring to maintain equipment” is the goal, while “Digital Twin” is the technology. If a certain technology is listed in the functions or index, it is equivalent to putting forward an explicit request for the path to accomplishing goals.',
  //typeC
  typeC1: 'Anticipated Functions and Index',
  typeC2: 'Development Foundation',
  typeC3: 'Foundation',
  typeC4: 'Description tips: please describe the existing software and hardware foundations (model/main functions).',
  //typeE
  typeE1: 'Links Involved',
  typeE2: 'Link',
  typeE3: 'Status',
  typeE4: 'Description angle prompt: please describe the name and main functions of each phase in order.',
  typeE5: 'Anticipated Functions and Index',
  //typeF
  typeF1: 'Current Business Problems',
  typeF2: 'Anticipated Solutions or Desired Support',
  //DetailBar
  detailbar1: 'Indefinite',
  detailbar2: 'to',
  detailbar3: 'Negotiable',
  detailbar4: 'wan',
  //Demand
  demand1: 'Release demand',
  demand2: 'My collection demand',
  demand3: 'Recommend',
  demand4: 'Past',
  demand5: 'Please enter the demand keyword',
  demand6: 'Please select region',
  demand7: 'Demand List',
  //PreviewC
  previewC1: 'The main scenarios of the production phase involved in this requirement are',
  //XConsulting
  xconsulting1: 'Consulting',
  xconsulting2: 'Collection',
  xconsulting3: 'We have received your inquiry',
  xconsulting4: 'If necessary, we will contact you within 1-2 working days.',
  xconsulting5: 'Close',
  xconsulting7: 'Sure',
  xconsulting6: 'Please enter the question for consultation\n' +
    '\n' +
    'You can ask questions about the technical issues and business cooperation of the demand to understand the issues related to the demand.',
  xconsulting8: 'After collecting, you can view this demand in my collection.',
  xconsulting9: 'You can leave a message to inquire about this demand, and the staff will contact you in time according to your inquiry',
  //UnderConstruction
  uc1: 'Coming Soon',
  //保密协议
  baomixieyi1: 'Statement of Demand Matching Platform',
  baomixieyi2: 'To protect your rights, please read the following carefully before logging in to this platform. By logging in to or using the website of ICIM you will be deemed to have carefully read this Statement, agreed to this Statement and agreed to comply with the provisions hereof, have corresponding right capacity and conduct capacity to browse the information on the platform and use the platform\'s technical demand matching service function, and can take legal liability for your behaviors. We would like to remind you that this platform has the right to make appropriate changes to this Statement. Please read this carefully.\n' + '\n' +
    'I.	Definitions\n' + '\n' +
    '1. Platform: the website of ICIM (www.icim.org.cn, www.online.icim.org.cn).\n' +
    '2. Platform Operator: Jiangsu Industrial Technology Research Institute undertakes the construction and operation of the Nanjing headquarters of ICIM, and is responsible for the daily operation and maintenance of the platform.\n' +
    '3. Platform Users: those who register and use this platform, including, without limitation, demand posters, technology suppliers, and intermediaries.\n' +
    '4. Demand Posters: those who post technical demands, including, without limitation, enterprises, institutions, and individuals with demands.\n' +
    '5. Technology Suppliers: those who provide technical research or solutions, including, without limitation, organizations, enterprises and experts.\n' +
    '6. Intermediaries: those who provide professional services to realize technical demand matching, including, without limitation, ICIM, Jiangsu Industrial Technology Research Institute, Yangtze River Delta National Technology Innovation Center, domestic and foreign societies and associations, and professional technical managers.\n' +
    '7. Demand Information: the technical demands disclosed by the demand posters on the platform and during specific technical demand matching, including, without limitation, technical plans, engineering designs, circuit designs, manufacturing methods, formula, process flows, specifications, computer software, database, R&D records, technical reports, test reports, experimental data, test results, drawings, samples, prototype machines, models, molds, operation manuals, technical documents and relevant correspondences.\n' + '\n' +
    'II. Posting and Handling Demand Information\n' + '\n' +
    '1. To achieve demand matching, the demand posters upload their demand information on to the platform, then the platform operator publicizes such demand information to the platform users.\n' +
    '2. The demand posters shall ensure the authenticity and legality of the demand information uploaded and undertake that no legal rights of any other party will be infringed upon.\n' +
    '3. The platform operator shall have the right to view, use, compare, disclose after anonymization, correlate, statistically collect, process and store the data generated by the users in the course of using the platform and the conclusive data made by the platform based on its judgment.\n' + '\n' +
    'III. Confidentiality Obligation\n' + '\n' +
    '1. The platform users shall bear the confidentiality obligation for the demand information known during the matching process, in the following manners:\n' +
    '(1) The demand information uploaded by each entity and disclosed by the platform to all platform users (hereinafter referred to as "Public Demand Information") shall not be disclosed to non-platform users in any manner; and\n' +
    '(2) Other demand information known from the posters in the course of specific technical matching (hereinafter referred to as "Other Demand Information") shall not be disclosed to any third party in any manner.\n' +
    '2. The platform users undertake to adopt reasonable confidentiality measures and systems to protect such secrets, the standard of which shall not be lower than the measures and systems used by the Receiving Party for the protection of its own trade secrets.\n' +
    '3. The above confidentiality provisions shall not apply to the following circumstances:\n' +
    '(1) The demand information has become or is becoming available to the general public; or\n' +
    '(2) It can be proved in writing that the demand information has been well known by the user before being received from the platform; or\n' +
    '(3) The information is lawfully provided by a third party; or\n' +
    '(4) Customer resources and technologies that are independently developed, which have the written proof of not using the owner\'s trade secrets.\n' +
    '4. Returning Information\n' +
    'At any time, upon receipt of a written request from the demand poster, the relevant party shall immediately return all demand information, the media containing the commercial data and any or all copies or extracts thereof. If the demand information is in a form which cannot be returned or has already been copied or transcribed into other data or media, it shall be deleted.\n' + '\n' +
    'IV. Detailed Rules for Users\n' + '\n' +
    '1. Since the prerequisite for demand matching is a user account on the platform, the platform users shall not transfer or lend their accounts and passwords to others. If the users find that their accounts or platform services are used by others, they shall notify the platform operator immediately. The platform operator shall have the right to refuse to assume any liability if the account, password and platform services are illegally used by others due to the user\'s negligence in custody.\n' +
    '2. The platform users shall be liable for their conduct in the course of using the platform, including but not limited to compensating the infringed parties.\n' +
    '3. You agree to indemnify and hold harmless the platform operators, cooperative companies and affiliated companies against any claim, demand or loss asserted by any third party, including reasonable attorney\'s fee, resulting from or arising from the breach of this Statement or relevant terms of service by the platform users.If the platform operator first bears administrative penalty or compensation liability caused by the behavior of a user, the user shall pay the platform operator the same amount of compensation.\n' +
    '4. The platform operator shall have the right to take measures including but not limited to deleting the information and content posted by the user, suspending the use license, terminating services, restricting the use, recycling the accounts, and pursuing legal liability depending on the nature of user\'s behaviors. The platform operator shall have the right to recycle the account of any person who registers an account malevolently or uses the account to conduct illegal activities, to disrupt, harass or defraud other users, and to perform other behaviors that violate this Statement. In the meantime, the platform operator shall assist the judicial authorities in the investigation upon request.\n' +
    'V. Miscellaneous\n' + '\n' +
    '1. This Statement shall be governed by, and construed in accordance with, the laws of the People\'s Republic of China. In the event that any dispute arises out of or in connection with the performance or interpretation of this Statement, relevant parties shall resolve such dispute through friendly negotiation. In case no settlement can be reached, it shall be submitted to Nanjing Arbitration Commission for arbitration in accordance with its arbitration rules.\n' +
    '2. If the platform users enter into other cooperation agreements in the process of demand matching, the rights and obligations of the corresponding signatories shall be implemented in accordance with such other cooperation agreements.\n' +
    '3. This Statement shall be deemed to have taken effect upon online signature by clicking the checkbox on the platform of International Coalition of Intelligent Manufacturing; the website of ICIM will keep the records of signature and consent, which are binding on all platform users.\n',

  baomixieyi3: 'International Coalition of Intelligent Manufacturing ',
  //隐私政策条款
  yinsizhengce1: 'Disagree, Log out.',
  yinsizhengce2: 'Terms and Conditions and Privacy Policy',
  yinsizhengce3: 'Website Legal Notice and Privacy Policy of International Coalition of Intelligent Manufacturing ',
  yinsizhengce4: 'Version: Version of April 20, 2021',
  yinsizhengce5: 'Notice\n' + '\n' +
    'Your trust is important to us. We are well aware of the importance of the security of the User Information. We will, according to the requirements of relevant laws and regulations, take security protection measures to protect the security and controllability of your User Information. In view of this, the International Coalition of Intelligent Manufacturing (“ICIM” or “We”) formulates this Legal Notice and Privacy Policy (“Notice and Policy”) and reminds you: Please read and thoroughly understand this Notice and Policy before you use the services of the ICIM Website, and use related products and services only after you confirm that you fully understand and agree to this Notice and Policy. By using the ICIM Website, you are deemed to have understood and fully agreed to the content of this Notice and Policy. If you have any questions, comments or suggestions about the content of this Notice and Policy, you may contact us by email (icim@jitri.cn) or call us on 025-58551017.\n' + '\n' +
    'Definitions\n' + '\n' +
    'The ICIM Website:\n' +
    'Domain name: http://www.icim.org.cn , https://online.icim.org.cn \n' +
    'International Coalition of Intelligent Manufacturing (“ICIM”) is the sole copyright owner of the ICIM Website.\n' +
    'User Information: “User Information” means user membership information, such as name and contact information, submitted by you or collected by us when you register an account with the ICIM Website, and use our services and we provide services to you. See the information described in Article 3 of the Privacy Policy for details.\n' + '\n' +

    'Legal Notice\n' + '\n' +
    '1.Ownership of Rights\n' +
    '1.1. The LOGO, “International Coalition of Intelligent Manufacturing”, “ICIM” and other words, graphics and their combinations on this website have been registered by ICIM as trademarks in China. Without the written authorization of ICIM, you shall not display, use or otherwise process the aforesaid words, graphics and their combinations in any way (including but not limited to copying, spreading, displaying, mirroring, uploading or downloading the same), nor shall you indicate to others that you have the right to display, use or otherwise process the same.\n' +
    '1.2. Unless otherwise stated by ICIM, all rights (including copyrights, trademark rights, patent rights, trade secrets and other related rights) to all products, technologies, software, programs, data and other information (including words, icons, pictures, photos, audios, videos, graphics, color combinations, layout design, etc.) on the ICIM Website shall be owned by the service providers of ICIM and/or its affiliated companies. Without the permission of the service providers of ICIM and/or its affiliated companies, no one may monitor, copy, spread, display, mirror, upload, download or otherwise use any contents on the ICIM Website through programs or devices such as robot and spider.\n' +
    '1.Limitation of Liability\n' +
    '2.1 The articles or materials reprinted on this website are only for promoting the international communication of intelligent manufacturing industry, popularizing advanced manufacturing technology ideas, promoting manufacturing information technology and facilitating the development of manufacturing enterprises, not for commercial purposes, and do not mean that the ICIM Website agrees with the viewpoints of or confirms the authenticity of the contents of such articles and materials.\n' +
    '2.2 The ICIM Website hereby reminds you that when using the services provided by this website, you shall comply with the laws of the People’s Republic of China, and shall not endanger network security, or use this website in any form to carry out any activities that infringe the reputation, privacy, intellectual property rights or any other legitimate rights and interests of others. The ICIM Website is not responsible for any legal consequences arising from your use of this website.\n' +
    'Privacy Policy\n' + '\n' +
    'The website of International Coalition of Intelligent Manufacturing (“ICIM Website” or “We”) respects and protects the User Information, and will treat the User Information with high diligence and prudent obligations. When you use the services provided by the ICIM Website, we will collect, process and disclose your information in accordance with this Privacy Policy. We hope, through this Privacy Policy, to introduce to you clearly how we process your information, so we suggest that you read this Privacy Policy completely to help you understand and maintain the ways to protect your privacy.\n' +
    'If you have any questions, comments or suggestions, please contact us through the contact information provided by the ICIM Website.\n' +
    'This Privacy Policy will help you understand the following contents:\n' +
    '1.Scope of Application\n' +
    '2.Collection and Use of the User Information\n' +
    'How Will We Protect the Security of Your Information\n' +
    '1.How We Share, Transfer and Publicly Disclose Your Personal Information\n' +
    '2.Reasonable Use of Information and Disclosure Principles\n' +
    '3.How We Use Cookie and Similar Technologies\n' +
    'Notice of and Amendments to the Privacy Policy\n' +
    'Contact Us\n' + '\n' +
    'I.Scope of Application\n' + '\n' +
    'This Privacy Policy shall apply to all services provided by the ICIM Website. Services include page browsing and website login service provided to you, and technical services provided to you through the ICIM Website.\n' +
    'Ⅱ. Collection and Use of the User Information\n' + '\n' +
    '2.1 Information we may collect\n' +
    '2.1.1 Some personal or business information you provide to the ICIM Website when you register a user account with the ICIM Website or use the services provided by the ICIM Website. The information collected may include:\n' +
    '1) Contact information: name, gender, age, birth date, telephone number, fax number, cell phone number, mailing address, home address, email address, etc.\n' +
    '2.2 How may we use information\n' +
    'We may use the collected information for the following purposes:\n' +
    '2.2.1 Providing you with the services that you need;\n' +
    '2.2.2 Helping design, optimize and upgrade the products and services of this platform;\n' +
    '2.2.3 Pushing advertisements, messages and notifications to you, or communicating with you.\n' +
    '2.3 If you only need to use basic services such as browsing and searching on the ICIM Website, you do not need to register as our member and provide the above information.\n' +
    '2.4 If you actively request cancellation of your account, please contact us. We will delete your personal information as soon as possible.\n' + '\n' +
    'Ⅲ. How Will We Protect the Security of Your Information\n' + '\n' +
    'We will take encrypted storage conforming to industry standards for your personal information, and adopt the same encrypting method to back up your personal information in different places to prevent your personal information from being lost, stolen or tampered with. We will take technical measures such as periodic scanning and regular penetration testing services to ensure the safety and reliability of services. We will manage and protect your personal information in strict accordance with national laws and regulations. However, please understand that there may be force majeure factors affecting information security, such as hacker attacks, computer virus invasion/outbreaks and government control.\n' +
    '1.How We Share, Transfer and Publicly Disclose Your Personal Information\n' +
    '(I) Sharing\n' +
    'We will not share your personal information with any company, organization, or individual other than the service provider of ICIM Website, with the following exceptions:\n' +
    '1.Sharing with explicit consent from you: We may share your personal information with others after obtaining your explicit consent.\n' +
    '2.Sharing under Statutory Circumstances: We may share your personal information with third parties according to the provisions of laws and regulations, the needs of litigation dispute resolution, or the requirements put forward by administrative and judicial authorities according to law.\n' +
    '3.Sharing with Third-party Service Providers: When you purchase goods or services on the ICIM Website, we must share your personal information with third-party service providers for the purposes of providing you with third-party logistics, installation services, etc.\n' +
    '4.Sharing with Affiliated Companies: We may share your personal information with our affiliated companies for the purposes of facilitating us to jointly provide services to you based on the affiliated accounts, recommending information you may be interested in, or protecting the personal and property safety of the affiliated companies of the ICIM Website or other users or the public from infringement. We will only share the necessary personal information (for example, we will share your necessary account information with our affiliated companies for the purpose of facilitating you to use your ICIM account to use the products or services of our affiliated companies). If we share your sensitive personal information or our affiliated companies change the purposes of using and processing personal information, we will request authorization and consent from you again.\n' +
    '5.Sharing with authorized partners: Some of our services will be provided by us and authorized partners together only for the purposes stated in this Privacy Policy. We may share some of your personal information with our partners for better customer service and user experience. For example, we must share your personal information with a logistics service provider to arrange delivery, or with our partner to provide services. We will only share your personal information for legal, legitimate, necessary, specific and clarified purposes, and will only share the necessary personal information needed for providing the service. Our partners are not entitled to use shared personal information for any other purposes irrelevant to the product or service.\n' +
    'At present, our authorized partners include the following types:\n' +
    '(1) Authorized partners of advertising and analysis services. We will not share your personal identity information with partners who provide advertising and analysis services without your permission. We will provide the aforesaid partners with information about the coverage and effectiveness of their advertisements, but will not provide them with your personally identity information, or we will summarize the identity information so that such partners cannot identify your personal identity information.\n' +
    '(2) Suppliers, service providers and other partners. We will send information to suppliers, service providers and other partners who support our business. Such support includes providing technical infrastructure services, analyzing the use methods of our services, measuring the effectiveness of advertisements and services, providing customer services, facilitating payment, or conducting academic research and investigation.\n' +
    'For companies, organizations and individuals with whom we share personal information, we will fully comply with our legal obligations and enter into a strict confidentiality agreement with them, which requires them to handle personal information in accordance with our instructions, this Privacy Policy, as well as any other relevant confidentiality and security measures.\n' +
    '(II) Transfer\n' +
    'We will not transfer your personal information to any company, organization or individual with the following exceptions:\n' +
    '1.Transfer with explicit consent from you: We will transfer your personal information to the other party with your explicit consent.\n' +
    '2.In case of merger, acquisition or bankruptcy liquidation of the service providers of the ICIM Website, or other circumstances involving merger, acquisition or bankruptcy liquidation, and if personal information transfer is involved, we will require the new companies or organizations holding your personal information to continue to be bound by this Privacy Policy, otherwise we will require such companies, organizations and individuals to request authorization and consent from you again.\n' +
    '(III) Public Disclosure\n' +
    'We will only publicly disclose your personal information under the following circumstances:\n' +
    '1.We may publicly disclose your personal information with your explicit consent or based on your active choice;\n' +
    '2.If we determine that you have violated laws and regulations or seriously breached the relevant agreement rules of the ICIM Website, or in order to protect the personal and property safety of the ICIM Website and the users of its affiliated companies or the public from infringement, we may disclose your personal information with your consent according to laws and regulations or relevant agreement rules of the ICIM Website, including relevant violations and measures taken by the ICIM Website against you. For example, if you sell fake goods, which seriously breaches the rules of the ICIM Website, we may publicly disclose the subject information of your store and penalties.\n' + '\n' +
    'Ⅴ. Reasonable Use of Information and Disclosure Principles\n' + '\n' +
    'We respect and protect the personal information of all users, and will make reasonable use and disclosure of the collected personal information only when one of the following conditions is met:\n' +
    '5.1 If the collected personal information is directly related to national security and national defense security;\n' +
    '5.2 If the collected personal information is directly related to public safety, public health, and major public interests;\n' +
    '5.3 If the collected personal information is directly related to criminal investigation, prosecution, trial and execution of judgments;\n' +
    '5.4 If the collected personal information serves the purpose of safeguarding important legal rights and interests such as life and property of the subject of personal information or other individuals while it is difficult to obtain the consent of the person;\n' +
    '5.5 If the collected personal information is the information that the subject of personal information discloses to the public by himself/herself;\n' +
    '5.6 If the collected personal information is your personal information which is collected from legally and publicly disclosed information, such as legal news reports, government information disclosure, and other channels;\n' +
    '5.7 If we have obtained your or your guardians’ written or equivalent written consent, or the collected personal information is the information necessary for signing a contract with you;\n' +
    '5.8 If the collected personal information is the information necessary for maintaining the safe and stable operation of the products and/or services provided, such as finding and handling the faults of the products and/or services;\n' +
    '5.9 If the collected personal information is the information necessary for legal news reporting;\n' +
    '5.10 If the collected personal information is the information necessary for academic research institutions to conduct statistical or academic research based on public interests, provided that the personal information contained in the results shall be de-identified when the academic research or description results are provided externally;\n' +
    '5.11 Other circumstances as stipulated by laws and regulations.\n' +
    '1.How We Use Cookie and Similar Technologies\n' +
    '6.1 Cookies refer to a type of technology. When a user visits this website with Cookies, the server of this website will automatically send Cookies to your browser and store the same in your computer hard disk. Such Cookies will be responsible for recording all kinds of activities, personal data, browsing habits, consumption habits and even credit records of your future visits to this website.\n' +
    '6.2 This platform can provide you with more thoughtful personalized services using the Cookies technology. This platform may use Cookies to follow your browsing habits and other preferences, so as to provide you with information that you are interested in or save passwords, so that you don’t have to enter your password repeatedly every time you visit this platform.\n' +
    '6.3 If you don’t want your personal information to be kept in cookies, you can configure your browser to disable cookies. However, after your close cookies, it may affect your use of the services of this platform.\n' + '\n' +
    'VII. Notice of and Amendments to the Privacy Policy\n' + '\n' +
    'The right to amend and update our Privacy Policy shall be owned by the International Coalition of Intelligent Manufacturing. If this Privacy Policy is changed, we will notify you by posting an announcement on the website, and the changed policy will apply as of the effective date specified in the notice or website. If you continue to use the services of this platform after this Privacy Policy is amended, it means that you have fully read, understood and accepted the amended policy and are willing to be bound by the same.\n' + '\n' +
    'VIII.  Contact Us \n' + '\n' +
    'If you have any questions or suggestions about the content of this Notice and Policy, you may contact us by email (icim@jitri.cn) or call us on 025-58551017.',
  //指导说明
  zhuyeshuoming1: 'User Guide',
  zhuyeshuoming2: 'Navigation Bar',
  zhuyeshuoming3: 'The Navigation Bar allows you to switch the language of the system, select to access the page you are interested in, search for information in the system or access your Personal Center.',
  zhuyeshuoming4: 'Personal Center',
  zhuyeshuoming5: 'The Personal Center allows you to edit or modify your personal information and add your technical tags according to which the system may recommend relevant information to you.',
  zhuyeshuoming6: 'Custom Menu',
  zhuyeshuoming7: 'You may customize the menu by adding or removing items or sub-items to it as you need, to better focus on the events you are interested in.',
  zhuyeshuoming8: 'Current Events',
  zhuyeshuoming9: 'In the main area of the home page, we provide you with the information of the current events initiated by ICIM for your convenience.',
  zhuyeshuoming10: 'Expert Recommendations',
  zhuyeshuoming11: 'We recommend ICIM experts in Expert Recommendations for you to consult and contact according to your business needs.',
  zhuyeshuoming12: 'Contact ICIM',
  zhuyeshuoming13: 'You may contact us directly to communicate about cooperation with ICIM or to answer your questions about the events. ',
  //我对接需求
  dc1: 'I need to connect',
  dc2: 'Undock',
  //MotherBoadr
  mboadr1: 'Add',
  mboadr2: 'Please click Add',
  mboadr3: 'Edit',
  mboadr4: 'Delete',
  //ExpertOrg
  eOrg1: 'Organization',
  eOrg2: 'Position/Title',
  //HonorAward
  haward1: 'Prize',
  haward2: 'Year',
  haward3: 'Name',
  haward4: 'Picture',
  haward5: 'Introduce',
  haward6: 'Enclosure',
  haward7: 'Address',
  //Notice
  notice1: 'Notification information list',
  notice2: 'All',
  notice3: 'Unread',
  notice4: 'Read',
  notice5: 'All types',
  notice6: 'Information in the station',
  notice7: 'Invitation information',
  notice8: 'Interactive information',
  notice9: 'Search',
  notice10: 'Delete',
  notice11: 'Mark Read',
  //NoticeDetail
  ndetail1: 'Return to notification list',
  ndetail2: 'Honorific',
  ndetail3: 'Download attachments',
  //XHeader
  xheader1: 'Edit data',
  xheader2: 'View Home Page',
  xheader3: 'All notification messages',
  //DemandQuick
  dquick1: 'Enter the requirement title and publish a new requirement',
  dquick2: 'Demand type',
  dquick3: 'Agree',
  dquick4: 'Confidentiality Agreement',
  dquick5: 'Demand',
  dquick6: 'Close',
  dquick7: 'Please enter a title',
  dquick8: 'Please select type',
  //DemandSearch
  dsearch1: 'Recommend',
  dsearch2: 'Newest',
  dsearch3: 'Please enter requirement keyword search',
  dsearch4: 'Advanced',
  dsearch5: 'Your search criteria',
  dsearch6: 'Key word',
  //DemandDetail
  ddetail1: 'Demand information',
  ddetail2: 'Interested experts',
  ddetail3: 'Demand consultation',
  ddetail4: 'Message consultation',
  ddetail5: 'Multiline input',
  ddetail6: 'Top',
  ddetail7: 'Jurisdiction',
  ddetail8: 'Enclosure',
  ddetail9: 'Send',
  ddetail10: 'Message list',
  ddetail11: 'Please enter the reply content',
  //UploadFile
  ufile1: 'Upload attachments',
  //ExpertInfo
  einfo1: 'Invite docking requirements',
  einfo2: 'Follow',
  einfo3: 'Cancel following',
  einfo4: 'Basic introduction',
  einfo5: 'Research field',
  einfo6: 'year',
  einfo7: 'Achievements',
  einfo8: 'Honors&Awards',
  einfo9: 'Original link',
  einfo10: 'Invitation',
  einfo11: 'Docking requirements',
  einfo12: 'Invitation description',
  einfo13: 'Please select',
  einfo14: 'Please complete the information',
  einfo15: 'Introduce',
  einfo16: 'Case',
  einfo17: 'Achievements&honors',
  einfo18: 'Media coverage',
  //ERight
  eright1: 'Follow him',
  eright2: 'Enterprise',
  eright3: 'Expert',
  eright4: 'No attention information',
  eright5: 'What he cares about',
  //NoData
  nodata: 'No data',
  //MediaCoverage
  mcoverage1: 'Please enter the story URL address, such as https://www.baidu.com/',
  //前台 需求列表搜索 技术领域校验
  checkReqTechFieldIds:'Select up to 3 technology areas',
}
export function getEnData(){
	return en;
}
export default en
