import $axios from '@/axios/index'

//通知列表
export const apiGetNotice = (params)=>{
  return $axios.get('/cbmp/req/listNotice', {params});
}
export const apiNotice = (params)=>{
  return $axios.get('/cbmp/req/readOrDeleteNotices', {params});
}
export const apiDeleteNotice = (params)=>{
  return $axios.delete('/cbmp/rest/Notice', params);
}

export const apiGetRestNotice = (params)=>{
  return $axios.get('/cbmp/rest/Notice/list', {params});
}
//邀请对接需求
export const apiPostRequirementInvite = (params)=>{
  return $axios.post('/cbmp/rest/RequirementInvite', params);
}
//关注
export const apiGetInterest = (params)=>{
  return $axios.get('/cbmp/rest/Interest/list', {params});
}
export const apiPostInterest = (params)=>{
  return $axios.post('/cbmp/rest/Interest', params);
}
export const apiDelInterest = (params)=>{
  return $axios.delete('/cbmp/rest/Interest', params);
}

export default {
  apiGetNotice,
  apiPostRequirementInvite,
  apiGetInterest,
  apiPostInterest,
  apiDelInterest,
  apiNotice,
  apiDeleteNotice
}
