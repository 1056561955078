import $axios from '../axios/index';

//查询地区/所在地
export const apiArea = ()=>{
  return $axios.get('/cbmp/rest/Area/list?asc=id');
}
//查询所属行业
export const apiIndustry = ()=>{
  return $axios.get('/cbmp/rest/Industry/list?asc=id');
}
//查询技术领域
export const apiTechnicalField = ()=>{
  return $axios.get('/cbmp/rest/TechnicalField/list?asc=sort');
}
//查询技术标签
export const apiTechnologyKeyword = ()=>{
  return $axios.get('/cbmp/rest/TechnologyKeyword/list');
}
export const apiPostTechnologyKeyword = (params, config={})=>{
  return $axios.post('/cbmp/rest/TechnologyKeyword', params, config);
}
//查询规避企业
export const apiEnterprise = ()=>{
  return $axios.get('/cbmp/rest/Enterprise/list');
}
export const apiPostEnterprise = (params, config={})=>{
  return $axios.post('/cbmp/rest/Enterprise', params, config);
}
//查询规避专家
export const apiExpert = ()=>{
  return $axios.get('/cbmp/rest/Expert/list');
}
export const apiPostExpert = (params, config={})=>{
  return $axios.post('/cbmp/rest/Expert', params, config);
}
//查询全球手机区号
export const apiMobilePrefix = ()=>{
  return $axios.get('/cbmp/rest/CountryMobilePrefix/list?asc=id');
}



