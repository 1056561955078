<template>
	<div id="app" :class="noTouch"><router-view></router-view></div>
</template>

<script>
import _ from 'lodash';
export default {
	name: 'App',
	data() {
		return {
			noTouch: '',
			exRoute: ['SignIn', 'ENSignIn', 'SignUp', 'ENSignUp', 'ForgotPwd', 'ENForgotPwd', 'Tips', 'ENTips', 'SystemLogin', 'ResetPwd', 'ENResetPwd'] //不查用户信息的路由
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.getUserInfo;
		},
		lang() {
			return this.$store.getters.getLanguage;
		}
	},
	watch: {
		$route(v) {
			let { name } = v;
			let lang = 'zh';
			if (name&&name.indexOf('EN') === 0) {
				lang = 'en';
			}
			console.log('name--', name);
			if (location.hash.indexOf('/System/') < 0) {
				this.$store.dispatch('setLanguage', lang);
				window.sessionStorage.setItem('lang', lang);
				this.$i18n.locale = lang;
			}
			let { breadcrumb, menuIndex } = v.meta;
			this.$store.dispatch('setBreadcrumb', breadcrumb || []);
			this.$store.dispatch('setMenuIndex', menuIndex);
			if ((!_.isObject(this.userInfo) || _.isEmpty(this.userInfo)) && !this.exRoute.includes(name)) {
				this.$store.dispatch('setUserInfo');
			}
		},
		userInfo(v) {
			if ((!v || (v && !v.name && v.id)) && location.hash.indexOf('/System/') < 0) {
				this.$message.info(this.$t('userinfo25'));
				let path = '/UserInfoSet';
				if (this.lang === 'en') {
					path = '/en/UserInfoSet';
				}
				this.$router.push(path);
			}
		}
	},
	mounted() {
		console.log('版本v1.2.48');
		if (window.sessionStorage.getItem('lang')) {
			this.$store.dispatch('setLanguage', window.sessionStorage.getItem('lang'));
		}
		let href = location.href;
		if((href.indexOf('system')>0||href.indexOf('System')>0)&&href.indexOf('/System/')<0){
      this.$router.replace('/System/Login');
    }
		if(!href.split('/#/')[1]&&href.indexOf('system')<0&&href.indexOf('System')<0){
      this.$router.replace('/SignIn');
    }
		//防复制开关
		let copyEnable = true;
		if (copyEnable) {
			this.noTouch = 'no-touch';
      //禁止右键
      this.$nextTick(()=>{
        document.oncontextmenu = function () {
          return false;
        }
      })
      //禁止复制 ctrl+c
      document.onkeydown = function(ev){
        if (ev.ctrlKey && (ev.key==='c'||ev.key==='C')){
          return false;
        }
      }
		}
	}
};
</script>
<style scoped>
/**加上该class后不能选中文字*/
.no-touch {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>
