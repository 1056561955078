import axios from 'axios'
import {Message} from 'element-ui'
import store from '../store'
import router from '@/router';
const baseUrl = window.ContextPath === undefined ? "" : window.ContextPath;
axios.interceptors.request.use(config=> {
  config.baseURL = baseUrl;
  config.withCredentials = true;
  //console.log('request_________________', config);
  store.dispatch("upBtnLoading", config.method!=='get'&&!config.noLoading);
  return config;
}, err=> {
  Message.error({message: '请求超时!'});
  return Promise.resolve(err);
});
let isLogin = false;
axios.interceptors.response.use(data=> {
  //console.log('reponse________________', data);
  let ddd = data&&data.data;
  if(data&&typeof data.data === 'string'&&data.headers['content-type']==='text/html;charset=UTF-8'){
    if(!isLogin){
      Message.error({ message: "请登录！" });
      setTimeout(()=>{
        if(location.hash.indexOf('/System/')>=0){
          router.replace("/System/Login");
        }else{
          router.replace("/SignIn");
        }
        isLogin = false;
      }, 2000);
    }
    isLogin = true;
  }else if (data&&data.status && data.status === 200 && data.data.status === 'error' ) {
    Message.error({message: data.data.message});
  }else if(data&&data.config&&data.config.method !== 'get'&&!data.config.noMsg&&ddd.success===true){
    Message.success({message: data.data.message});
  }else if (data&&data.status && data.status === 502){
    Message.error({
      message: "服务连接失败，稍后再试"
    })
  }else{
    //
  }
  store.dispatch("upBtnLoading", false);

  if(ddd.success===false && !data.config.noMsg){
    ddd = [];
    Message.error({message: data.data.message});
  }
  return ddd;
}, err=> {
  Message.error({message: err.response&&err.response.data.message});
  store.dispatch("upBtnLoading", false);
  return Promise.resolve(err);
})
export default axios
