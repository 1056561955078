import {apiSaveOrUpdate} from "../../api";
export default {
  state: {
    step: 0,
    typeObj:{//需求类型
      'A1': {
        name: '产品/设备使用场景-软硬件',
        nameEn: 'Product/device usage scenario - software and hardware',
      },
      'A2':{
        name: '产品/设备使用场景-纯软件',
        nameEn: 'Product/device usage scenario - pure software',
      },
      'A3':{
        name: '产品/设备使用场景-纯硬件',
        nameEn: 'Product/device usage scenario - pure hardware',
      },
      'B1':{
        name: '工艺设计或生产载体设计或改造-软硬件',
        nameEn: 'Process design or production carrier design or transformation - software and hardware',
      },
      'B2':{
        name: '工艺设计或生产载体设计或改造-纯软件',
        nameEn: 'Process design or production carrier design or transformation - pure software',
      },
      'B3':{
        name: '工艺设计或生产载体设计或改造-纯硬件',
        nameEn: 'Process design or production carrier design or transformation - pure hardware',
      },
      'C1':{
        name: '生产环节业务场景A-软硬件',
        nameEn: 'Production phase business scenario a - software and hardware',
      },
      'C2':{
        name: '生产环节业务场景A-纯软件',
        nameEn: 'Production phase business scenario a - pure software',
      },
      'C3':{
        name: '生产环节业务场景A-纯硬件',
        nameEn: 'Production phase business scenario a - pure hardware',
      },
      'D1':{
        name: '服务环节业务场景A-软硬件',
        nameEn: 'Service phase business scenario a - software and hardware',
      },
      'D2':{
        name: '服务环节业务场景A-纯软件',
        nameEn: 'Service phase business scenario a - pure software',
      },
      'D3':{
        name: '服务环节业务场景A-纯硬件',
        nameEn: 'Service phase business scenario a - pure hardware',
      },
      'E1':{
        name: '多环节或复杂系统',
        nameEn: 'Multi link or complex system',
      },
      'F1':{
        name: '人员协调/数字化转型项目推进',
        nameEn: 'Personnel coordination / digital transformation project promotion',
      },
      'F2':{
        name: '企业管理-管理咨询',
        nameEn: 'Enterprise management - management consulting',
      },
    },
    //合作方式
    cooperationTypeOpt: [
      { id: "1", name: '技术转让', nameEn: 'Technology transfer' },
      { id: "2", name: '技术入股', nameEn: 'Technology investment' },
      { id: "3", name: '联合开发', nameEn: 'Joint development' },
      { id: "4", name: '委托研发', nameEn: 'Consigned R&D' },
      { id: "5", name: '委托团队/专家长期技术服务', nameEn: 'Entrust teams/experts for long-term technical service' },
      { id: "6", name: '共建研发/生产实体', nameEn: 'Collaborate to establish R&D/production entity' },
      { id: "7", name: '其他', nameEn: 'Others' },
    ],
    //合作对象倾向
    partnerTypeOpt: [
      { id: "1", name: '不限', nameEn: 'Unlimited' },
      { id: "2", name: '企业', nameEn: 'Enterprise' },
      { id: "3", name: '高校院所', nameEn: 'College/university/institute' },
      { id: "4", name: '联合团队', nameEn: 'Joint team' }
    ],
    //合作对象距离
    partnerRegionTypeOpt: [
      { id: "1", name: '不限', nameEn: 'Unlimited' },
      { id: "2", name: '本区域', nameEn: 'This district' },
      { id: "3", name: '本省', nameEn: 'This province' },
      { id: "4", name: '本市', nameEn: 'This municipality' },
      { id: "5", name: '距离优先', nameEn: 'Distance priority' },
    ],
    //功能&指标
    funcListOpt: [
      { id: 'tradition', name: '传统技术', nameEn: 'Traditional technology' },
      { id: 'digital', name: '数字化技术', nameEn: 'Digital Technology' },
      { id: 'intell', name: '数字化+智能化', nameEn: 'Digital + Network Technology' },
      { id: 'network', name: '数字化+智能化+网络化', nameEn: 'Digital + Network + Intelligent Technology' }
    ],
    //涉及的系统和环节，当前状态
    systemLinkListOpt: [
      { id: 'developed', name: '已开发完毕', nameEn: 'Has been developed' },
      { id: 'undeveloped_belong', name: '未开发，属本次需求内容', nameEn: 'Undeveloped, but to be developed this time' },
      { id: 'undeveloped_unbelong', name: '未开发，不属本次需求内容', nameEn: 'Undeveloped, and not to be developed this time' }
    ],
    cacheDemand: {
      id: '',
      type: '',
    },
    //发布需求步骤
    stepData: [
      { id: '1', name: '需求类型', nameEn: 'Demand type' },
      { id: '2', name: '需求概要', nameEn: 'Demand summary' },
      { id: '3', name: '需求详情', nameEn: 'Demand details' },
      { id: '4', name: '需求预览', nameEn: 'Demand preview' },
    ]
  },
  getters: {
    getStep: (state)=>{
      return state.step;
    },
    getStepData: (state)=>{
      return state.stepData;
    },
    getCacheDemand: (state)=>{
      return state.cacheDemand;
    },
    getCooperationTypeOpt: (state)=>{
      return state.cooperationTypeOpt;
    },
    getPartnerTypeOpt: (state)=>{
      return state.partnerTypeOpt;
    },
    getPartnerRegionTypeOpt: (state)=>{
      return state.partnerRegionTypeOpt;
    },
    getFuncListOpt: (state)=>{
      return state.funcListOpt;
    },
    getSystemLinkListOpt: (state)=>{
      return state.systemLinkListOpt;
    },
    getTypeObj: (state)=>{
      return state.typeObj;
    },
  },
  mutations: {
    setStep(state, n){
      state.step = n;
    },
    setCacheDemand(state, data){
      state.cacheDemand = data;
    },
    saveDemand(state, data){
      let {id} = state.cacheDemand;
      let params = {
        ...state.cacheDemand,
        ...data,
      };
      if(id!==""){
        params.id = id;
      }
      apiSaveOrUpdate(params).then(res=>{
        if(res.success){
          let {id, enterpriseId} = res.ext;
          state.cacheDemand = {
            ...params,
            id,
            enterpriseId
          };
        }
      })
    }
  },
  actions: {
    setStep({commit}, n){
      commit('setStep', n);
    },
    setCacheDemand({commit}, data){
      commit('setCacheDemand', data);
    },
    saveDemand({commit}, data){
      commit('saveDemand', data);
    }
  }
}
