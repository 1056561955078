import {apiArea, apiIndustry, apiTechnicalField, apiEnterprise, apiExpert, apiMobilePrefix} from '../../api/index';
export default {
  state: {
    gender: [
      { id: "male", name: "男", nameEn: 'Male' },
      { id: "female", name: "女", nameEn: 'Female' },
      { id: "nvl", name: "保密", nameEn: 'Confidentiality' },
    ],
    nature: [
      //{ id: "personal", name: "个人", nameEn: "personal" },
      { id: "enterprise", name: "企业会员", nameEn: "enterprise" },
      { id: "expert", name: "专家", nameEn: "expert" },
      //{ id: "community", name: "校园/社区", nameEn: "community" },
    ],
    //personal("个人"), enterprise("企业会员"), expert("专家"), community("校园/社区");
    area: [],
    category: [],
    domain: [],
    avoidEnterprise: [],
    avoidExpert: [],
    mobilePrefix: [],
    enterpriseOpt: [
      { id: "1", name: '民营企业', nameEn: '' },
      { id: "2", name: '国企', nameEn: '' },
      { id: "3", name: '央企', nameEn: '' },
      { id: "4", name: '外资企业', nameEn: '' },
      { id: "5", name: '境外企业', nameEn: '' },
    ],
    insizeOpt: [
      { id: "1", name: '100人以下', nameEn: '' },
      { id: "2", name: '100~300人', nameEn: '' },
      { id: "3", name: '300~500人', nameEn: '' },
      { id: "4", name: '500~1000人', nameEn: '' },
      { id: "5", name: '1000~3000人', nameEn: '' },
      { id: "6", name: '3000人以上', nameEn: '' },
    ],
  },
  getters: {
    getEnterprise: (state)=>{
      return state.enterpriseOpt;
    },
    getInsize: (state)=>{
      return state.insizeOpt;
    },
    getGender: (state)=>{
      return state.gender;
    },
    getNature: (state)=>{
      return state.nature;
    },
    getArea: (state)=>{
      return state.area;
    },
    getCategory: (state)=>{
      return state.category;
    },
    getAvoidEnterprise: (state)=>{
      return state.avoidEnterprise;
    },
    getAvoidExpert: (state)=>{
      return state.avoidExpert;
    },
    getDomain: (state)=>{
      return state.domain;
    },
    getMobilePrefix: (state)=>{
      return state.mobilePrefix;
    },
  },
  mutations: {
    setArea(state, data){
      state.area = data;
    },
    setCategory(state, data){
      state.category = data;
    },
    setAvoidEnterprise(state, data){
      state.avoidEnterprise = data;
    },
    setDomain(state, data){
      state.domain = data;
    },
    setAvoidExpert(state, data){
      state.avoidExpert = data;
    },
    setMobilePrefix(state, data){
      state.mobilePrefix = data;
    },
  },
  actions: {
    setArea({commit}){
      apiArea().then(res=>{
        commit('setArea', res.result);
      });
    },
    setCategory({commit}){
      apiIndustry().then(res=>{
        commit('setCategory', res.result);
      });
    },
    setDomain({commit}){
      apiTechnicalField().then(res=>{
        commit('setDomain', res.result);
      });
    },
    setAvoidEnterprise({commit}){
      apiEnterprise().then(res=>{
        commit('setAvoidEnterprise', res.result);
      });
    },
    setAvoidExpert({commit}){
      apiExpert().then(res=>{
        commit('setAvoidExpert', res.result);
      });
    },
    setMobilePrefix({commit}){
      apiMobilePrefix().then(res=>{
        commit('setMobilePrefix', res.result);
      });
    },
  }
}
